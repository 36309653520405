import { type ReactNode, useState } from 'react'
import { Link } from '@oahz/neact'
import { BEMGenerator, useRCComputed } from '@oahz/neact-utils'
import { Button, Checkbox } from '@bedrock/components'
import { formatDateTime, formatMoenyAmount } from '@feature/shared'
import { ExpressBox, Eye } from '@bedrock/icons-react'
import { bizEnums, type defs } from '@/services'
import { setDeliveryStorageData } from '@/components/order'
import { DataMoney } from '@/components/data-view'
import { WorkbenchListItem, ListFieldView, ListFieldLable } from '../'
import './style.less'

const bem = BEMGenerator('cwb-ship')

export const ShipListItemHeader = (props: {
  data: defs.FmWaitDeliveryListDto
  batchData: string[]
  lines: defs.FmWaitDeliveryLineDto[]
  onSelect: (val: string[]) => void
}) => {
  const { data, batchData, onSelect, lines } = props
  const { receivingInfo = {} } = data

  const indeterminate = useRCComputed(() => {
    return batchData.length > 0 && batchData.length < lines.length
  }, [batchData, data])

  const checked = useRCComputed(() => {
    return batchData.length === lines.length
  }, [batchData, data])

  return (
    <div className={bem('slih')}>
      <div className={bem('slih-id')}>
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={e => onSelect(e.target.checked ? lines.map(v => v.id || '') : [])}
        />
        <div>{data.orderNo}</div>
      </div>
      <div className={bem('slih-info')}>
        <div>{`${receivingInfo.receiver || '-'}(${receivingInfo.contactWay || '-'})`}</div>
        <ListFieldLable label={receivingInfo.address || '-'} />
      </div>
      <div className={bem('slih-time')}>
        <ListFieldView label="下单时间" value={formatDateTime(data.orderTime)} />
      </div>
    </div>
  )
}

const CountInfo = (props: { info: defs.FmWaitDeliveryLineDto }) => {
  const { shippingQuantity, orderQuantity, orderAmount, shippingAmount, orderMethod } = props.info

  if (!props.info) return '-'

  if (orderMethod === bizEnums.PlaceOrderMethodEnum.AMOUNT) {
    return (
      <ListFieldView
        label="可发货/下单金额"
        value={
          <div>
            <DataMoney showSign={false} size="xsmall" value={shippingAmount} />/{formatMoenyAmount(orderAmount)}
          </div>
        }
      />
    )
  }
  return (
    <ListFieldView
      label="可发货/下单数量"
      value={
        <div>
          <span className={bem('label-warning')}>{shippingQuantity}</span>/{orderQuantity}
        </div>
      }
    />
  )
}

export const ShipListItemDataView = (props: {
  lines: defs.FmWaitDeliveryLineDto[]
  batchData?: string[]
  onSelect: (val: string[]) => void
}) => {
  const { lines, batchData, onSelect } = props

  return (
    <div className={bem('slidv')}>
      <Checkbox.Group value={batchData} onChange={val => onSelect(val as string[])}>
        {lines.map((item, index) => (
          <div className={bem('slidv-item')} key={item.id}>
            <div className={bem('slidv-item-name')}>
              <Checkbox key={item.id} value={item.id} />
              <div title={item.goodsName || '-'} className={bem('slidv-item-name-text')}>
                {item.goodsName || '-'}
              </div>
            </div>
            <div className={bem('slidv-item-brand')}>
              <ListFieldView label="品牌" value={item.goodsBrand} />
            </div>
            <div className={bem('slidv-item-config')}>
              <ListFieldView label="配置" value={item.goodsConfiguration || '-'} />
            </div>
            <div className={bem('slidv-item-count')}>
              <CountInfo info={item} />
            </div>
          </div>
        ))}
      </Checkbox.Group>
    </div>
  )
}

export const ShipListItemFooter = (props: { batchData: string[]; id?: string; orderNo?: string }) => {
  const { batchData, id, orderNo } = props

  const createShip = () => {
    const key = setDeliveryStorageData(id || '', batchData, orderNo)
    window.open(`/order/ship-create?key=${key}`)
  }

  return (
    <div className={bem('slif')}>
      <Link to={`/order/show?id=${id}`} target="_blank">
        <Button icon={<Eye />} type="checked">
          查看
        </Button>
      </Link>
      <Button disabled={!batchData?.length} onClick={createShip} icon={<ExpressBox />} type="primary">
        发货
      </Button>
    </div>
  )
}

export const ShipListItemView = (props: { data: defs.FmWaitDeliveryListDto }) => {
  const { data } = props
  const [batchData, setBatchData] = useState<string[]>([])
  const onSelect = (val: any[]) => {
    setBatchData(val)
  }

  const _lines = useRCComputed(() => {
    return data?.lines?.filter(v => v.status !== bizEnums.FmOrderLineStatusEnum.DELIVERED) || []
  }, [data.lines])

  if (!data.lines) return <></>
  return (
    <section className={bem()}>
      <WorkbenchListItem
        header={<ShipListItemHeader data={data} lines={_lines} batchData={batchData} onSelect={onSelect} />}
        footer={<ShipListItemFooter batchData={batchData} id={data?.id} orderNo={data?.orderNo} />}>
        <ShipListItemDataView lines={_lines} batchData={batchData} onSelect={onSelect} />
      </WorkbenchListItem>
    </section>
  )
}
