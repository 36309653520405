import { ReactNode } from 'react'
import { BEMGenerator, useRCComputed } from '@oahz/neact-utils'
import { Button, Empty, InputNumber, Select } from '@bedrock/components'
import { Upload } from '@bedrock/icons-react'
import {
  DataAttachments,
  DataAttachmentsProps,
  FilePreviewAndDownloadServices,
  FileUpload,
  FileUploadProps,
  TimeCountDown,
  ValueCopyView,
} from '@feature/views'
import I18N from '@feature/i18n'
import { formatDateTime } from '@feature/shared'
import { useService } from '@oahz/neact'
import { SelectProps } from '@bedrock/components/lib/Select'
import SvgNoData from '@bedrock/components/es/Empty/images/large/NoData'
import { apiService, bizEnums, bizService, defs } from '@/services'
import './style.less'

const bem = BEMGenerator('cq')

export const QutotationUpload = (props: Omit<FileUploadProps, 'dataId'>) => {
  return (
    <FileUpload
      {...props}
      dataId=""
      requestUploadFile={apiService.quotationQuotationRequestUploadFile}
      completeUploadFile={apiService.quotationQuotationCompleteUploadFile}
      getDownloadUrl={apiService.quotationQuotationGetAttachmentDownloadUrl}
      trigger={
        <Button type="checked" prefix={<Upload />}>
          {I18N.auto.shangChuanFuJian}
        </Button>
      }
    />
  )
}

const poFilePreviewAndDownloadServices: FilePreviewAndDownloadServices = {
  getPreviewUrl: v => apiService.quotationQuotationGetAttachmentPreviewUrl({ fileId: v.fileId }),
  getDownloadUrl: v => apiService.quotationQuotationGetAttachmentDownloadUrl({ fileId: v.fileId }),
}

export const QutotationAttachments = (props: DataAttachmentsProps) => {
  return <DataAttachments {...props} {...poFilePreviewAndDownloadServices} />
}

const QutotationStatusFieldView = (props: { label: string; value?: ReactNode | string }) => {
  const { label, value } = props
  return (
    <div className={bem('qsfv')}>
      <div className={bem('qsfv-label')}>{label}</div>
      <div className={bem('qsfv-value')}>{value || '-'}</div>
    </div>
  )
}

export const QutotationStatusView = (props: { data: defs.QuotationDetailDto; onEnd?: () => void }) => {
  const { data = {}, onEnd } = props
  const showTime = [bizEnums.QuotationStatusEnum.WAIT_QUOTATION, bizEnums.QuotationStatusEnum.HAS_QUOTATION].includes(
    data.status!
  )
  return (
    <div className={bem('qsv', [bem(`qsv-${data.status}`)])}>
      <div className={bem('qsv-l')}>
        <div className={bem('qsv-l-status')}>{data.status && bizEnums.QuotationStatusEnum.pick(data.status)?.name}</div>
        <div className={bem('qsv-l-field')}>
          <QutotationStatusFieldView label="报价单号" value={<ValueCopyView value={data.id} />} />
          {data.status !== bizEnums.QuotationStatusEnum.WAIT_QUOTATION && (
            <>
              <QutotationStatusFieldView label="报价人" value={data.quoteUser?.name} />
              <QutotationStatusFieldView label="报价时间" value={formatDateTime(data.createdAt, 'YYYY-MM-DD')} />
            </>
          )}
          {data.status === bizEnums.QuotationStatusEnum.ABANDONED_BID && (
            <QutotationStatusFieldView label="取消时间" value={data.id} />
          )}
        </div>
      </div>
      {showTime && (
        <div className={bem('qsv-r')}>
          <TimeCountDown
            label={data.isClarification ? '报价澄清倒计时：' : '报价倒计时：'}
            targetDate={data.deadlineAt}
            onEnd={onEnd}
          />
        </div>
      )}
    </div>
  )
}

export const LineAnchorTitleView = (props: { data: defs.QuotationLineDto; title: string }) => {
  const { data, title } = props
  const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
  return (
    <div className={bem('latv')}>
      <div className={bem('latv-title')}>
        {title}
        {data.goodsName}
      </div>
      {isQuantity && (
        <div className={bem('latv-info')}>
          <div className={bem('latv-info-label')}>{'询价数量'}</div>
          <div className={bem('latv-info-value')}>{data.quantity}</div>
        </div>
      )}
    </div>
  )
}

export const AmountInput = (props: {
  className?: string
  currency: defs.Currency
  value?: defs.MoneyCommand
  onChange?: (v?: defs.MoneyCommand) => void
  max?: number
  min?: number
  step?: number
}) => {
  const { className, value, onChange, currency, max, step, min = 0 } = props

  return (
    <div className={bem('ami', [className])}>
      <div className={bem('ami-currency')}>{currency}</div>
      <InputNumber
        trimOnBlur
        max={max}
        min={min}
        toFixed={['JPY', 'KRW'].includes(currency) ? 0 : 2}
        value={value?.amount}
        onChange={v => onChange?.({ currency: currency, amount: Number(v) })}
        {...(step ? { step: step } : { addonAfter: null, direction: 'vertical' })}
      />
    </div>
  )
}

export const QuoteLaxSelect = (props: SelectProps) => {
  const { data } = useService(bizService.rfxQuoteListTaxRates)
  const options = useRCComputed(() => data?.map(v => ({ name: `${v.percentageValue}%`, value: v.value! })), [data])
  return <Select options={options} showSearch="inner" {...props} />
}

export const QuotationLineEmpty = () => (
  <div className={bem('qle')}>
    <Empty size="small" imageNode={<SvgNoData />} text={<span className={bem('empty-text')}>暂无报价</span>} />
  </div>
)

const PERIOD_OPTIONS = [
  { name: I18N.auto.yongJiu, value: -1 },
  ...Array(100)
    .fill(1)
    .map((_, i) => ({ name: I18N.template(I18N.auto.geYue, { val1: i }), value: i })),
]

export const LicensingPeriodSelect = (props: SelectProps) => {
  return <Select options={PERIOD_OPTIONS} showSearch="inner" {...props} />
}

export const QuotationUpload = (props: Omit<FileUploadProps, 'dataId'>) => {
  return (
    <FileUpload
      {...props}
      dataId=""
      requestUploadFile={apiService.fmFmRequestUploadFile}
      completeUploadFile={apiService.fmFmCompleteUploadFile}
      getDownloadUrl={apiService.fmFmGetAttachmentDownloadUrl}
      trigger={
        <Button type="checked" prefix={<Upload />}>
          {I18N.auto.shangChuanFuJian}
        </Button>
      }
    />
  )
}
