import { useManualService, useRoute, useRouter } from '@oahz/neact'
import { BEMGenerator, useRCMount, useRCUpdate } from '@oahz/neact-utils'
import { apiService, bizEnums, type defs } from '@/services'
import { PageContainer, MainContainer, PageHeader, PageLoading } from '@/components/page-view'
import Show from '../show'
import Bidding from '../bidding'
export default () => {
  const { data, loading, request, mutate } = useManualService(apiService.quotationQuotationDetail)
  const { query } = useRoute()
  useRCMount(() => query.id && request({ id: query.id }))

  if (loading || !data) return <PageLoading />

  if (!data?.isBidding && data?.quotationMethod === bizEnums.QuotationMethodEnum.ONLINE) {
    return <Show data={data} id={query.id} />
  }

  return <Bidding data={data} id={query.id} mutate={mutate} refresh={() => request({ id: query.id })} />
}
