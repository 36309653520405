import { useMemo, useState } from 'react'
import { BEMGenerator, useRCMount } from '@oahz/neact-utils'
import { DataCard, DataGrid, DataItem, DataForm, DataFormItem, AsyncButton } from '@feature/views'
import { Button, Table, Form, Input, DatePicker, Select, InputNumber } from '@bedrock/components'
import { Check, Clock, Close } from '@bedrock/icons-react'
import type { ColumnsType } from '@bedrock/components/lib/Table'
import I18N from '@feature/i18n'
import { useManualService, useRoute, useRouter } from '@oahz/neact'
import { MainContainer, PageContainer, PageContent, PageHeader, PageLoading } from '@/components/page-view'
import {
  CourierCompanySelect,
  CreateDeliveryAmount,
  DeliveryLineGoodsInfo,
  getDeliveryStorageData,
  POValueCopyView,
  removeStorageData,
  ShipUpload,
} from '@/components/order'
import { apiService, bizEnums, type defs } from '@/services'
import { useSelector, useDispatch } from '@/store'
import { ListMoneyView } from '@/components/data-view'
import './style.less'

const bem = BEMGenerator('psc')

const genSubmitParams = (formVal: any) => {
  const deliveryInfo: defs.FmDeliveryInfoCommand = Object.fromEntries(
    Object.entries(formVal).filter(([key, value]) => !key.includes('-'))
  )
  const linesVal: defs.FmDeliveryInfoCommand = Object.fromEntries(
    Object.entries(formVal).filter(([key, value]) => key.includes('-'))
  )
  let lines = []
  for (var key in linesVal) {
    // 从键名中获取索引和属性名
    var parts = key.split('-')
    var index = parseInt(parts[0].replace('lines', ''))
    var prop = parts[1]
    // 如果结果数组中还没有对应索引的对象，就创建一个
    if (!lines[index]) {
      lines[index] = {}
    }
    // 将值添加到对应索引的对象中
    lines[index][prop] = formVal[key]
  }
  return { deliveryInfo, lines } as defs.FmDeliveryCommand
}

const DeliveryDetails = (props: { poData?: defs.FmOrderDetailDto; data: defs.FmOrderDetailLineDto[] }) => {
  const { poData, data } = props
  const unTaxBenchmark = poData?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  const columns: ColumnsType<defs.FmOrderDetailLineDto> = [
    { dataIndex: 'lineNo', title: 'PO行号', width: 80, align: 'left', render: val => val.split('-')?.[1] },
    {
      dataIndex: 'goodsInfo',
      title: I18N.auto.shangPinXinXi,
      width: 280,
      align: 'left',
      render: (_, record) => (
        <DeliveryLineGoodsInfo
          value={{
            goodsInfo: record?.goodsName,
            brand: record.goodsBrand,
            configuration: record.goodsConfiguration,
          }}
        />
      ),
    },
    {
      dataIndex: 'info',
      title: `可发货/履约信息${unTaxBenchmark ? '（未税）' : '（含税）'}`,
      width: 165,
      align: 'right',
      render: (_, record) =>
        record.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY ? (
          record.shippingQuantity
        ) : (
          <ListMoneyView value={unTaxBenchmark ? record.shippedUnTaxAmount : record.shippingAmount} />
        ),
    },
    {
      dataIndex: 'quantity_amount',
      title: (
        <span className={bem('title-required')}>{`本次发货/履约信息${unTaxBenchmark ? '（未税）' : '（含税）'}`}</span>
      ),
      width: 165,
      align: 'left',
      render: (_, record, index) => {
        const isQuantity = record.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
        const initialValue = isQuantity
          ? record.shippingQuantity
          : unTaxBenchmark
            ? record.shippingUnTaxAmount
            : record.shippingAmount
        const name = isQuantity
          ? `lines${index}-${`quantity`}`
          : `lines${index}-${`${unTaxBenchmark ? 'unTaxAmount' : 'amount'}`}`
        return (
          <Form.Item
            name={name}
            required
            rules={[
              {
                required: true,
                message: '此项必填',
                validator: (_: any, value: any) => {
                  if (!value || value <= 0) {
                    return Promise.reject()
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
            initialValue={initialValue}>
            {isQuantity ? (
              <InputNumber size="xSmall" min={0} max={record.shippingQuantity} addonAfter={null} direction="vertical" />
            ) : (
              <CreateDeliveryAmount
                shippingAmount={unTaxBenchmark ? record.shippingUnTaxAmount! : record.shippingAmount!}
              />
            )}
          </Form.Item>
        )
      },
    },
    {
      dataIndex: 'attachments',
      title: '附件',
      width: 270,
      align: 'left',
      render: (_, record, index) => (
        <>
          <Form.Item name={`lines${index}-attachments`}>
            <ShipUpload limit={5} tip="" />
          </Form.Item>
          <Form.Item style={{ display: 'none' }} name={`lines${index}-lineNo`} initialValue={record.lineNo}>
            <Input />
          </Form.Item>
          <Form.Item style={{ display: 'none' }} name={`lines${index}-orderMethod`} initialValue={record.orderMethod}>
            <Input />
          </Form.Item>
        </>
      ),
    },
    {
      dataIndex: 'remark',
      title: '发货行备注',
      width: 200,
      align: 'left',
      render: (_, __, index) => (
        <Form.Item name={`lines${index}-remark`}>
          <Input.TextArea maxLength={200} showCount />
        </Form.Item>
      ),
    },
  ]

  return (
    <Table
      className={bem('table')}
      outerBorder={false}
      data={data || []}
      columns={columns}
      scroll={{ x: true }}
      rowKey={(_, i) => i!}
    />
  )
}

export default () => {
  const { query } = useRoute()
  const [fm] = Form.useForm()
  const { push } = useRouter()
  const [type, setType] = useState<defs.DeliveryType>()
  const [lineIds, setLineIds] = useState<string[]>([])
  const { userInfo } = useSelector(state => state.account)
  const { getWorkbenchStatistics } = useDispatch(dispatch => dispatch.workbenchStatistics)
  const { data, loading, request } = useManualService(apiService.fmFmDetail)
  const orderData = useMemo(() => (query.key ? getDeliveryStorageData(query.key) : undefined), [query])
  useRCMount(() => {
    if (query.key) {
      const orderData = getDeliveryStorageData(query.key)
      if (orderData?.orderId) {
        request({ id: orderData?.orderId })
        setLineIds(orderData?.lineIds || [])
      }
    }
  })

  const doSubmit = async () => {
    try {
      await fm.validateFields()
      const formVal = await fm.getFieldsValue()
      const params: defs.FmDeliveryFulfillmentCommand = genSubmitParams(formVal)
      // 这个id是什么id
      const res = await apiService.fmFmDelivery({ id: data?.id! }, params)
      getWorkbenchStatistics()
      removeStorageData(query.key)
      res && push(`/order/ship-show?id=${res}`)
    } catch (error) {
      console.log(error)
    }
  }
  const onCancel = () => window.close()

  if (loading) return <PageLoading />

  return (
    <PageContainer className={bem()}>
      <PageHeader
        title={`新建发货单`}
        action={
          <>
            <Button icon={<Close size={16} />} type="checked-neutral" onClick={onCancel}>
              取消
            </Button>
            <AsyncButton icon={<Check size={16} />} type="primary" onClick={doSubmit}>
              提交
            </AsyncButton>
          </>
        }
      />
      <PageContent className={bem('main')}>
        <MainContainer>
          <DataForm form={fm} className={bem('content')}>
            <DataCard title={'收货信息'}>
              <DataGrid>
                <DataItem label="收货人" value={data?.receivingInfo?.receiver} />
                <DataItem label="电话" value={data?.receivingInfo?.contactWay} />
                <DataItem w100 label="邮箱" value={data?.receivingInfo?.receiverEmail} />
                <DataItem label="州/省/市" value={data?.receivingInfo?.deliveryRegion} />
                <DataItem label="邮编" value={data?.receivingInfo?.postalCode} />
                <DataItem w100 label="收货地址" value={data?.receivingInfo?.address} />
              </DataGrid>
            </DataCard>
            <DataCard title={'发货信息'}>
              <DataGrid>
                <DataFormItem name="deliverer" label="发货人" required initialValue={userInfo?.name}>
                  <Input />
                </DataFormItem>
                <DataFormItem name="deliveryTime" label="发货时间" required>
                  <DatePicker showTime={{ format: 'HH' }} suffixIcon={<Clock />} />
                </DataFormItem>
                <DataFormItem name="type" label="发货类型" required>
                  <Select options={bizEnums.DeliveryTypeEnum.values()} onChange={e => setType(e)} />
                </DataFormItem>
                <DataFormItem name="estimateArrivalTime" label="预计到货日期" required>
                  <DatePicker />
                </DataFormItem>
                {type === bizEnums.DeliveryTypeEnum.EXPRESS_AND_LOGISTICS && (
                  <>
                    <DataFormItem name="courierCompanyId" label="物流公司" required>
                      <CourierCompanySelect />
                    </DataFormItem>
                    <DataFormItem name="trackingNo" label="物流单号" required>
                      <Input />
                    </DataFormItem>
                  </>
                )}
                <DataFormItem w100 name="remark" label="供应商发货备注">
                  <Input.TextArea showCount maxLength={2000} />
                </DataFormItem>
                <DataFormItem w100 name="attachments" label="发货单附件">
                  <ShipUpload limit={20} fileSizeLimit={1024 * 1024 * 1024 * 1} tip={'单个文件小于1GB'} />
                </DataFormItem>
              </DataGrid>
            </DataCard>
            <DataCard title={'发货明细'} action={<POValueCopyView orderNo={orderData?.orderNo} />}>
              <DataGrid>
                <DeliveryDetails
                  poData={data}
                  data={data?.orderLines?.filter(v => v.id && lineIds.includes(v.id)) || []}
                />
              </DataGrid>
            </DataCard>
          </DataForm>
        </MainContainer>
      </PageContent>
    </PageContainer>
  )
}
