import { ReactNode, useState } from 'react'
import { getLanguage, LangEnum } from '@feature/i18n'
import { Button, Dropdown, Form, Input, Menu } from '@bedrock/components'
import { ChevronDown, Language } from '@bedrock/icons-react'
import { Link, useRoute, useService } from '@oahz/neact'
import Select, { SelectProps } from '@bedrock/components/lib/Select'
import { BEMGenerator, useRCComputed } from '@oahz/neact-utils'
import { FormInstance } from '@bedrock/components/lib/Form'
import { apiService, LOCALES } from '@/services'
import './style.less'

const bem = BEMGenerator('csigin')

export const SinginHeader = () => {
  return (
    <header className={bem('header')}>
      <Link to="/">
        <div className={bem('logo')} />
      </Link>
    </header>
  )
}

export const SinginContainer = (props: { className?: string; children?: ReactNode }) => {
  const { className, children } = props
  return (
    <div className={bem('container', [className])}>
      <SinginHeader />
      <div className={bem('container-content')}>{children}</div>
    </div>
  )
}

export const LoginInLocal = () => {
  // const { userInfo = {} } = useSelector(state => state.account)
  const { query } = useRoute()
  const locale: LangEnum = query?.lang || LangEnum['zh-CN']
  const doChangeLocale = async (locale: string) => {
    // await store.dispatch.account.setUserInfo({ locale: locale })
    window.location.reload()
  }

  const overlayBasic = () => {
    return (
      <Menu key="2">
        {Object.entries(LOCALES).map(([k, v]) => (
          <Menu.Item title={LOCALES[locale!].title} key={k} onClick={() => doChangeLocale(k)}>
            {v.title}
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  return (
    <Dropdown overlay={overlayBasic()}>
      <Button
        type="text-subtle"
        suffixIcon={<ChevronDown className="rock-dropdown-down-arrow-icon" />}
        icon={<Language />}>
        {LOCALES[getLanguage()].title}
      </Button>
    </Dropdown>
  )
}

export const CountrySelect = (props: SelectProps) => {
  const { data } = useService(apiService.identityEnterpriseRegistrationCountries)
  const options = useRCComputed(() => data?.map(v => ({ name: v.name!, value: `${v.id}_${v.code}` })), [data])
  return <Select options={options} showSearch="inner" {...props} />
}

export const VerificationCodeInput = (props: {
  mail: string
  value?: string
  onChange?: (v?: string) => void
  className?: string
}) => {
  let countdown = 60
  const { mail, className, value, onChange } = props
  const [disabled, setDisabled] = useState(false)
  const [btnText, setBtnText] = useState('获取验证码')

  const fetchCode = async () => {
    try {
      setDisabled(true)
      mail && (await apiService.identityAuthenticationSendResetPasswordCode({ mail: mail }))
    } catch (error) {
      console.log(error)
    } finally {
      var timer = setInterval(function () {
        countdown--
        if (countdown <= 0) {
          // 倒计时结束，清除定时器
          clearInterval(timer)
          // 设置按钮为可点击
          setDisabled(false)
          // 设置按钮显示的文本
          setBtnText('获取验证码')
          countdown = 60
        } else {
          // 更新按钮显示的倒计时
          setBtnText(`${countdown}s 重新获取`)
        }
      }, 1000)
    }
  }

  return (
    <div className={bem('vc', [className])}>
      <Input trimOnBlur value={value} placeholder="邮箱验证码" onChange={e => onChange?.(e.target.value)} />
      <Button disabled={!mail || disabled} type="checked" onClick={fetchCode}>
        {btnText}
      </Button>
    </div>
  )
}

export const SupplierVerificationCode = (props: {
  mail: string
  value?: string
  onChange?: (v?: string) => void
  className?: string
  api?: (params: { mail: string }) => Promise<any>
  beforFetchCode?: () => Promise<any>
}) => {
  let countdown = 60
  const { mail, className, value, onChange, api, beforFetchCode } = props
  const [disabled, setDisabled] = useState(false)
  const [btnText, setBtnText] = useState('获取验证码')

  const fetchCode = async () => {
    await beforFetchCode?.()
    const _api = api || apiService.identityEnterpriseRegistrationSendVerificationCode
    try {
      setDisabled(true)
      mail &&
        (await _api({
          mail: mail,
        }))
    } catch (error) {
      console.log(error)
    } finally {
      var timer = setInterval(function () {
        countdown--
        if (countdown <= 0) {
          // 倒计时结束，清除定时器
          clearInterval(timer)
          // 设置按钮为可点击
          setDisabled(false)
          // 设置按钮显示的文本
          setBtnText('获取验证码')
          countdown = 60
        } else {
          // 更新按钮显示的倒计时
          setBtnText(`${countdown}s 重新获取`)
        }
      }, 1000)
    }
  }

  return (
    <div className={bem('vc', 'vc-s', [className])}>
      <Input
        trimOnBlur
        value={value}
        placeholder="请输入"
        onChange={e => onChange?.(e.target.value)}
        autoComplete="off"
      />
      <Button disabled={!mail || disabled} type="checked" onClick={fetchCode}>
        {btnText}
      </Button>
    </div>
  )
}

export const PasswordFormItem = (props: { name?: string; lable?: ReactNode; placeholder?: string }) => {
  const { name = 'password', lable = '新密码', placeholder = '请输入9-15位的大小写字母、数字、特殊字符的组合' } = props
  return (
    <Form.Item
      name={name}
      label={lable}
      validateTrigger="onBlur"
      rules={[
        {
          required: true,
          validator: (_, value) => {
            let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{9,15}$/
            if (!value) {
              return Promise.reject('请输入密码')
            } else if (!regex.test(value)) {
              return Promise.reject('请输入9-15位的大小写字母、数字、特殊字符的组合')
            } else {
              return Promise.resolve()
            }
          },
        },
      ]}>
      <Input.Password autoComplete="off" trimOnBlur minLength={9} maxLength={15} placeholder={placeholder} />
    </Form.Item>
  )
}

export const ConfirmPasswordFormItem = (props: { form: FormInstance; label?: string }) => {
  const { form, label = '再次输入新密码' } = props
  return (
    <Form.Item
      name="confirmPassword"
      label={label}
      validateTrigger="onBlur"
      rules={[
        {
          required: true,
          validator: (rule, value) => {
            const password = form.getFieldValue('password')
            if (!value) {
              return Promise.reject('请输入密码')
            } else if (value !== password) {
              return Promise.reject('请输入相同密码')
            } else {
              return Promise.resolve()
            }
          },
        },
      ]}>
      <Input.Password autoComplete="off" trimOnBlur minLength={9} maxLength={15} placeholder="请再次输入密码" />
    </Form.Item>
  )
}
