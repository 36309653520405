import type { ReactNode } from 'react'
import { useRoute } from '@oahz/neact'
import { BEMGenerator, useRCVars } from '@oahz/neact-utils'
import type { QueryParams } from '@bedrock-e/common'
import I18N from '@feature/i18n'
import { AppTable, createTableColumns, AppDefaultTableHeader, ShowCutOffTime } from '@feature/views'
import { ELightSelect, ELightDatePicker } from '@bedrock-e/common'
import { apiService, bizEnums, type defs } from '@/services'
import { QuotationStatus, DataLink } from '@/components/data-view'
import { PageContainer, PageList } from '@/components/page-view'
import './style.less'

const bem = BEMGenerator('pquote')

interface ColumnsProps extends Omit<defs.QuotationListDto, 'QuotationLineListDto'>, defs.QuotationLineListDto {
  rowSpan: number
}

const RfxPageLink = (props: { rfxId: string; children?: string; underline?: boolean }) => {
  const { rfxId, children, underline = true } = props
  if (!rfxId) return '-'
  return (
    <DataLink href={`/quotation/show?id=${rfxId}`} underline={underline}>
      {children || rfxId}
    </DataLink>
  )
}

const genListData = (data: defs.QuotationListDto[]) => {
  return data.reduce((acc: defs.QuotationLineListDto[], item: defs.QuotationListDto) => {
    const { lines, ...rest } = item
    const newAsnLineList = !lines?.length
      ? [item]
      : lines?.map((line, index) => ({
          ...line,
          ...rest,
          rowSpan: index === 0 ? lines.length : 0,
        })) || []
    return [...(acc || []), ...newAsnLineList]
  }, [])
}

const Action = (props: { record: ColumnsProps }) => {
  const { record } = props
  const { status, id, quotable } = record
  let text = '查看详情'
  if (quotable && status === bizEnums.QuotationStatusEnum.WAIT_QUOTATION) {
    text = '报价'
  }
  if (quotable && status === bizEnums.QuotationStatusEnum.HAS_QUOTATION) {
    text = '变更报价'
  }
  return (
    <RfxPageLink rfxId={id || ''} underline={false}>
      {text}
    </RfxPageLink>
  )
}

export default () => {
  const { query } = useRoute()
  const vars = useRCVars({ keyword: query.keyword as string })

  const colums = createTableColumns<ColumnsProps>([
    {
      dataIndex: 'rfxId',
      title: '询价单号',
      width: 180,
      align: 'left',
      fixed: 'left',
      onCell: row => ({ rowSpan: row.rowSpan }),
      render: (v, record) => <RfxPageLink rfxId={record?.id || ''}>{v}</RfxPageLink>,
    },
    {
      dataIndex: 'status',
      title: '状态',
      width: 130,
      align: 'left',
      fixed: 'left',
      onCell: row => ({ rowSpan: row.rowSpan }),
      render: v => <QuotationStatus value={v} />,
    },
    {
      dataIndex: 'rfxLineId',
      title: '询价行号',
      width: 90,
      align: 'left',
      render: v => (v ? v.split('-')[1] : '-'),
    },
    {
      dataIndex: 'goodsName',
      title: I18N.auto.shangPinMingCheng,
      width: 170,
      align: 'left',
      render: v => v || '-',
    },
    {
      dataIndex: 'goodsBrand',
      title: I18N.auto.pinPai,
      width: 140,
      align: 'left',
      render: v => v || '-',
    },
    {
      dataIndex: 'goodsConfiguration',
      title: I18N.auto.peiZhi,
      width: 153,
      align: 'left',
      paragraph: true,
    },
    {
      dataIndex: 'deadlineAt',
      title: '截至时间',
      width: 209,
      align: 'left',
      render: (v, record) => (
        <ShowCutOffTime
          targetTime={record?.isClarification ? record.clarificationDeadlineAt : v}
          format="YYYY-MM-DD HH:mm:ss"
        />
      ),
    },
    {
      dataIndex: 'quantity',
      title: '询价数量',
      width: 105,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'settlementCompanyName',
      title: '询价公司',
      width: 200,
      align: 'left',
      render: v => (v !== undefined ? v : '-'),
    },
    {
      dataIndex: 'options',
      title: '操作',
      width: 100,
      align: 'left',
      fixed: 'right',
      onCell: row => ({ rowSpan: row.rowSpan }),
      render: (_, record) => {
        return <Action record={record} />
      },
    },
  ])

  const queryItems = [
    {
      name: 'statusSet',
      label: I18N.auto.zhuangTai,
      children: <ELightSelect options={bizEnums.QuotationStatusEnum.values()} />,
    },
    {
      name: 'deadline',
      label: '报价截止时间',
      children: <ELightDatePicker range />,
    },
  ]

  const request = (params: Pick<QueryParams, 'orderItemList'>) => {
    // @ts-ignore
    const [deadlineStart, deadlineEnd] = params.deadline || []
    const genParmas: defs.QuotationQuery = {
      ...params,
      // @ts-ignore
      deadline: undefined,
      deadlineStart: deadlineStart,
      deadlineEnd: deadlineEnd,
    }

    return apiService.quotationQuotationQueryRfxLineList(genParmas).then(res => {
      return {
        records: genListData(res?.records || []),
        total: res?.total,
      }
    })
  }

  const renderHeader = ({ setting, doRequest }: { setting: ReactNode; doRequest: (params: any) => void }) => (
    <AppDefaultTableHeader
      onQuery={doRequest}
      queryItems={queryItems}
      actions={<div />}
      settings={setting}
      queryInput={{
        defaultValue: vars.keyword,
        placeholder: '请输入询价单号/商品名称',
      }}
    />
  )

  return (
    <PageContainer className={bem()} htmlTitle={'报价管理'}>
      <PageList className={bem('list')}>
        <AppTable
          rowKey={'rfxLineId'}
          request={request}
          // hideOnSinglePage
          columns={colums}
          // settable={{ id: 'quotationList' }}
          className={bem('table')}
          renderHeader={renderHeader}
        />
      </PageList>
    </PageContainer>
  )
}
