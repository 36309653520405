import { useState } from 'react'
import { BEMGenerator, useRCMount, useBoolean } from '@oahz/neact-utils'
import { useManualService, useRoute } from '@oahz/neact'
import { DataContainer, DataCard, DataGrid, DataItem, DataBlock } from '@feature/views'
import { LogisticsArrive, Plus, TaskShow } from '@bedrock/icons-react'
import { Table, Button, Modal } from '@bedrock/components'
import { formatDateTime } from '@feature/shared'
import type { ColumnsType } from '@bedrock/components/lib/Table'
import { PageContainer, MainContainer, PageHeader, PageLoading } from '@/components/page-view'
import { apiService, bizEnums, type defs } from '@/services'
import { CompanyView, DataMoney, DataTaxRate, ListMoneyView, renderPeriod } from '@/components/data-view'
import {
  formatOrderTime,
  OrderLineAnchorTitleView,
  OrderLineDeliveryCount,
  OrderLineHeader,
  PoAttachments,
  setDeliveryStorageData,
} from '@/components/order'
import { useSelector } from '@/store'
import './style.less'

const bem = BEMGenerator('pos')

const CreatBtn = (props: {
  id: string
  data?: defs.FmOrderDetailDto
  list?: defs.FmOrderDetailLineDto[]
  orderNo?: string
}) => {
  const { id, data, list = [], orderNo } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [batchLines, setBatchLines] = useState<defs.FmOrderDetailLineDto[]>([])
  const tableList = list.filter(v => v.status !== bizEnums.FmOrderLineStatusEnum.DELIVERED)
  const unTaxBenchmark = data?.moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED

  const columns: ColumnsType<defs.FmOrderDetailLineDto> = [
    { dataIndex: 'lineNo', title: '行号', width: 100, align: 'left', render: v => v?.split('-')[1] },
    { dataIndex: 'goodsName', title: '商品名称', width: 170, align: 'left' },
    { dataIndex: 'goodsBrand', title: '品牌', width: 140, align: 'left' },
    { dataIndex: 'goodsConfiguration', title: '配置', width: 153, align: 'left' },
    { dataIndex: 'shippingQuantity', title: '可发货数量', width: 110, align: 'right' },
    {
      dataIndex: 'shippingAmount',
      title: `可履约金额${unTaxBenchmark ? '（未税）' : '（含税）'}`,
      width: 114,
      align: 'right',
      render: (v, record) => {
        return <ListMoneyView value={unTaxBenchmark ? record.shippingUnTaxAmount : record.shippingAmount} />
      },
    },
  ]

  const close = () => {
    setBatchLines([])
    hide()
  }

  const handleOk = async () => {
    const _deliveryIds: string[] = batchLines.map(item => item.id || '')
    const key = setDeliveryStorageData(id, _deliveryIds, orderNo)
    window.open(`/order/ship-create?key=${key}`)
    close()
  }

  return (
    <>
      <Button icon={<Plus size={16} />} type="primary" onClick={show}>
        新建发货单
      </Button>
      <Modal
        className={bem('creat-modal')}
        title={'新建发货单'}
        width={900}
        visible={visible}
        onCancel={close}
        onOk={handleOk}
        okButtonProps={{
          disabled: !batchLines?.length,
        }}
        okText={'提交'}>
        <Table
          rowKey="id"
          columns={columns}
          data={tableList}
          scroll={{ x: true }}
          emptyCellRender={() => '-'}
          onSelectChange={setBatchLines}
          rowSelection={{ type: 'checkbox', selectedRowKeys: batchLines.map(v => v.id) }}
        />
      </Modal>
    </>
  )
}

const MoneyItem = (props: {
  value?: defs.Money | defs.MoneyDto | number
  referenceValue?: defs.MoneyDto
  highlight?: boolean
  size?: 'xsmall' | 'small' | 'default' | 'large'
  fontWeight?: 'normal' | 'bold' | 'extraBold'
  centerLine?: boolean
  showSign?: boolean
}) => {
  const { value } = props
  if (value === undefined || value === null) return '-'
  return <DataMoney {...props} />
}

const LineView = (props: { data: defs.FmOrderDetailLineDto }) => {
  const { data } = props
  const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY

  const columns: ColumnsType<defs.FmOrderDetailLineDeliveryDto> = [
    { dataIndex: 'id', title: '发货单号', width: 180, align: 'left' },
    { dataIndex: 'trackingNo', title: '物流单号', width: 170, align: 'left' },
    { dataIndex: 'courierCompanyName', title: '物流公司', width: 107, align: 'left' },
    {
      dataIndex: 'estimateArrivalTime',
      title: '预计到货日期',
      width: 177,
      align: 'left',
      render: v => formatDateTime(v, 'YYYY-MM-DD'),
    },
    {
      dataIndex: 'deliveryTime',
      title: '发货日期',
      width: 174,
      align: 'left',
      render: v => formatDateTime(v, 'YYYY-MM-DD'),
    },
    {
      dataIndex: 'quantity',
      title: data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY ? '发货数量' : '履约金额(含税)',
      width: 114,
      align: 'left',
      render: (_, record) =>
        data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY ? (
          record.shippedQuantity
        ) : (
          <DataMoney size="xsmall" fontWeight="bold" highlight={false} value={record?.shippedAmount} />
        ),
    },
  ]

  return (
    <DataCard
      id={data.id}
      collapsable
      title={<OrderLineHeader title={`Line${data.lineNo?.split('-')[1] || ''}`} status={data.status!} />}>
      <DataBlock icon={<TaskShow />} title="需求信息">
        <DataGrid>
          <DataItem w100 label="商品名称" value={<span className={bem('goodsName')}>{data.goodsName}</span>} />
          <DataItem label="品牌" value={data.goodsBrand} />
          <DataItem label="配置" value={data.goodsConfiguration} />
          <DataItem w100 label="授权期限 (月)" value={data.licensingPeriod} />
          <DataItem w100 label="订单行备注" value={data.remark} />
          <DataItem w100 label="订单行附件" value={<PoAttachments dataId={''} attachments={data.attachments} />} />
        </DataGrid>
        <DataGrid>
          {isQuantity && (
            <DataItem
              label={'单价（未税）'}
              value={<MoneyItem size="xsmall" showSign={false} highlight={false} value={data.unitUnTaxMoney} />}
            />
          )}
          <DataItem label="税率" value={<DataTaxRate value={data.taxRate} />} />
          <DataItem label="运费（含税)" value={<MoneyItem value={data.freight} />} />
          {isQuantity ? (
            <>
              <DataItem
                label="数量"
                value={
                  data.orderQuantity !== undefined ? (
                    <span className={bem('orderQuantity')}>{data.orderQuantity}</span>
                  ) : (
                    '-'
                  )
                }
              />
              <DataItem label={'单价 (含税)'} value={<MoneyItem fontWeight="extraBold" value={data.unitMoney} />} />
              <DataItem label={'金额（含税)'} value={<MoneyItem fontWeight="extraBold" value={data.orderAmount} />} />
            </>
          ) : (
            <>
              <DataItem
                label={'金额（未税)'}
                value={<MoneyItem fontWeight="extraBold" value={data.orderUnTaxMoney} />}
              />
              <DataItem label={'金额（含税)'} value={<MoneyItem fontWeight="extraBold" value={data.orderMoney} />} />
            </>
          )}
          <DataItem label="期望到货日期" value={formatDateTime(data.expectedDeliveryDate, 'YYYY-MM-DD')} />
          <DataItem label="维保期限 (月)" value={renderPeriod(data.maintenancePeriod)} />
          <DataItem
            w100
            label={`发票类型`}
            value={data.invoiceType && bizEnums.InvoiceTypeEnum.pick(data.invoiceType)?.name}
          />
        </DataGrid>
      </DataBlock>
      {!!data.deliveryLines?.length && (
        <DataBlock icon={<LogisticsArrive />} title="发货信息" extra={<OrderLineDeliveryCount data={data} />}>
          <Table
            rowKey="id"
            columns={columns}
            data={data.deliveryLines || []}
            scroll={{ x: true }}
            emptyCellRender={() => '-'}
          />
        </DataBlock>
      )}
    </DataCard>
  )
}

export default () => {
  const { query } = useRoute()
  const { supplierInfo } = useSelector(state => state.account)
  const { data, loading, request } = useManualService(apiService.fmFmDetail)
  useRCMount(() => query.id && request({ id: query.id }))

  if (loading) return <PageLoading />

  return (
    <PageContainer className={bem()}>
      <PageHeader
        title={`订单详情：${data?.orderNo}`}
        action={<CreatBtn id={query.id} data={data} list={data?.orderLines || []} orderNo={data?.orderNo} />}
      />
      <MainContainer>
        <DataContainer
          className={bem('main')}
          anchor={(data?.orderLines || []).map(item => ({
            id: item.id!,
            element: <OrderLineAnchorTitleView data={item} />,
          }))}
          scrollOffsetTop={156}>
          <DataCard title={'基本信息'}>
            <DataGrid>
              <DataItem
                label="购买方"
                value={
                  <CompanyView
                    companyName={data?.settlementCompanyName}
                    companyCode={data?.settlementCompanyCountryCode}
                  />
                }
              />
              <DataItem
                label="抬头公司"
                value={
                  <CompanyView
                    companyName={data?.settlementCompanyName}
                    companyCode={data?.settlementCompanyCountryCode}
                  />
                }
              />
              <DataItem w100 label="销售方" value={<span className={bem('user-name')}>{supplierInfo?.name}</span>} />
              <DataItem w100 label="下单时间" value={formatDateTime(data?.orderTime)} />
              <DataItem label="订单号" value={data?.orderNo} />
              <DataItem label="合同号" value={data?.contractNumber} />
              <DataItem
                label="支付方式"
                value={data?.paymentMethod && bizEnums.PaymentMethodEnum.pick(data?.paymentMethod)?.name}
              />
              <DataItem
                label="账期"
                value={data?.accountPeriod && bizEnums.AccountPeriodEnum.pick(data?.accountPeriod)?.name}
              />
              <DataItem
                label={`订单金额\n(未税)`}
                value={<DataMoney fontWeight="bold" size="xsmall" highlight={false} value={data?.orderUnTaxMoney} />}
              />
              <DataItem
                label={`订单金额\n(含税)`}
                value={<DataMoney fontWeight="extraBold" highlight value={data?.orderMoney} />}
              />
              <DataItem w100 label="备注信息" value={data?.remark} />
              <DataItem w100 label="附件" value={<PoAttachments dataId={''} attachments={data?.attachments} />} />
            </DataGrid>
          </DataCard>
          <DataCard title={'收货信息'}>
            <DataGrid>
              <DataItem label="收货人" value={data?.receivingInfo?.receiver} />
              <DataItem label="电话" value={data?.receivingInfo?.contactWay} />
              <DataItem w100 label="邮箱" value={data?.receivingInfo?.receiverEmail} />
              <DataItem label="州/省/市" value={data?.receivingInfo?.deliveryRegion} />
              <DataItem label="邮编" value={data?.receivingInfo?.postalCode} />
              <DataItem
                w100
                label="收货地址"
                value={[
                  data?.receivingInfo?.countryName,
                  data?.receivingInfo?.stateName,
                  data?.receivingInfo?.cityName,
                  data?.receivingInfo?.address,
                ]
                  .filter(item => !!item)
                  .join('-')}
              />
            </DataGrid>
          </DataCard>
          {data?.orderLines?.map(item => <LineView key={item.id} data={item} />)}
        </DataContainer>
      </MainContainer>
    </PageContainer>
  )
}
