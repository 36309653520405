import { ReactNode, ReactElement, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { useService, usePrompt } from '@oahz/neact'
import { BEMGenerator, useRCWatch, useRCUpdate, useRCVars, useRCComputed, useMultiState } from '@oahz/neact-utils'
import {
  Button,
  Empty,
  Message,
  Upload,
  Form,
  Modal,
  Link,
  Tag,
  Select,
  InputNumber,
  DatePicker,
  Divider,
  Checkbox,
} from '@bedrock/components'
import type { SelectProps } from '@bedrock/components/lib/Select'
import type { UploadFile, BrFile, UploadChangeParam, UploadProps } from '@bedrock/components/lib/Upload/types'
import type { FormProps, FormItemProps } from '@bedrock/components/lib/Form'
import type { ButtonProps } from '@bedrock/components/lib/Button'
import type { InputNumberProps } from '@bedrock/components/lib/InputNumber'
import type { DatePickerProps } from '@bedrock/components/lib/DatePicker'
import ErrorImage from '@bedrock/components/es/Empty/images/small/Error'
import { Copy, Eye } from '@bedrock/icons-react'
import dayjs from 'dayjs'
import I18N from '@feature/i18n'
import { downloadFile, formatMoenyAmount, getFileSizeUnit, getMoneyNumberSign, openFile } from '@feature/shared'
import { type defs, bizService, bizEnums } from '@/services'
import './style.less'

const bem = BEMGenerator('cdv')

export const DataPanel = (props: { id?: string; className?: string; children?: ReactNode }) => {
  const { id, className, children } = props
  return (
    <div id={id} className={bem('dp', [className])}>
      {children}
    </div>
  )
}

export const DataBlock = (props: {
  className?: string
  bodyClassName?: string
  title?: ReactNode
  extra?: ReactNode
  bizLine?: boolean
  children?: ReactNode
}) => {
  const { className, bodyClassName, title, extra, bizLine = false, children } = props
  return (
    <div className={bem('db', { line: bizLine }, [className])}>
      {title && (
        <div className={bem('db-header')}>
          <div className={bem('db-sign')} />
          <div className={bem('db-title')}>{title}</div>
          {extra && <div className={bem('db-extra')}>{extra}</div>}
        </div>
      )}

      <div className={bem('db-body', [bodyClassName])}>{children}</div>
    </div>
  )
}

export type DateItemProps = {
  className?: string
  w100?: boolean
  w667?: boolean
  w500?: boolean
  color?: string
  label: ReactNode
  value?: ReactNode
  children?: ReactNode
}
export const DataItem = (props: DateItemProps) => {
  const { className, w100 = false, w667 = false, w500 = false, color, label, value, children } = props
  const rValue = value || children
  return (
    <div className={bem('di', { w100, w667, w500 }, [className])}>
      <div className={bem('di-label')}>{label}</div>
      {label && (
        <div className={bem('di-value')} style={rValue ? { color } : {}}>
          {rValue === 0 ? rValue : rValue || '-'}
        </div>
      )}
    </div>
  )
}

export const DataDivider = () => {
  return <Divider className={bem('divider')} type="horizontal" />
}

export const DataStatus = (props: { className?: string; name?: string }) => {
  const { className, name } = props
  return <div className={bem('status', [className])}>{name ?? '-'}</div>
}

export const QuotationStatus = (props: { className?: string; value?: string }) => {
  const { className, value } = props
  return (
    <DataStatus
      className={bem('status-quotation', { [value!]: true }, [className])}
      name={value && bizEnums.QuotationStatusEnum.pick(value)?.name}
    />
  )
}

export const getMoneyText = (v?: defs.Money | defs.MoneyDto) => {
  // 金额数值要千分位格式化
  return v && `${`${v.amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

// TODO: 重写一下，简化版本的
export const DataMoney = (props: {
  value?: defs.Money | defs.MoneyDto | number
  referenceValue?: defs.MoneyDto
  highlight?: boolean
  size?: 'xsmall' | 'small' | 'default' | 'large'
  fontWeight?: 'normal' | 'bold' | 'extraBold'
  centerLine?: boolean
  showSign?: boolean
}) => {
  const { value, highlight = true, fontWeight, centerLine = false, size = 'default', showSign = true } = props
  const weight = highlight && !fontWeight ? 'weight-bold' : `weight-${fontWeight || 'normal'}`
  const [sign, setSign] = useState('')

  const currency = (v: typeof value) => {
    return typeof value !== 'number' && value?.currency ? `${value?.currency} ` : ''
  }

  const normalize = (v: typeof value) => {
    return typeof value === 'number' ? value : formatMoenyAmount(value)
  }

  useRCWatch(() => {
    if (typeof value === 'number' || !value?.amount) return
    return setSign(getMoneyNumberSign(value.amount))
  }, [value])

  if (value === undefined) return null

  return (
    <div className={bem('money')}>
      <div
        className={bem('money-value', 'money-currency', `money-value-${weight}`, { highlight, line: centerLine }, [
          bem(`money-value-${size}`),
        ])}>
        {currency(value)}
      </div>
      <div className={bem('money-c')}>
        <div className={bem('money-row')}>
          <div
            className={bem('money-value', `money-value-${weight}`, { highlight, line: centerLine }, [
              bem(`money-value-${size}`),
            ])}>
            {normalize(value)}
          </div>
        </div>
        {!!sign && showSign && (
          <div className={bem('money-tip')}>
            <Tag className={bem('money-sign')} text={sign} color="light-grey" size="xSmall" />
          </div>
        )}
      </div>
    </div>
  )
}

export const MoneyInput = (props: InputNumberProps) => {
  const sign = useRCComputed(() => {
    return props.value ? getMoneyNumberSign(props.value as number) : ''
  }, [props.value])

  return (
    <div className={bem('input-money')}>
      <InputNumber direction="vertical" trimOnBlur {...props} />
      {!!sign && <Tag text={sign} color="light-grey" size="xSmall" />}
    </div>
  )
}

export const CurrencyMoneyInput = (
  props: Omit<InputNumberProps, 'defaultValue' | 'value' | 'onChange'> & {
    defaultValue?: defs.MoneyDto
    value?: defs.MoneyDto
    onChange?: (v?: defs.MoneyDto) => void
    currencyDisabled?: boolean
  }
) => {
  const { className, defaultValue = {}, currencyDisabled = false, value: propValue, onChange, ...restProps } = props
  const [data, setData] = useMultiState(defaultValue)

  useRCUpdate(() => {
    onChange?.(data.amount && data.currency ? { amount: data.amount, currency: data.currency } : undefined)
  }, [data])

  return (
    <div className={bem('currencyMoney', [className])}>
      <CurrencySelect
        disabled={currencyDisabled}
        value={data.currency}
        onChange={v => setData({ currency: v as defs.Currency })}
      />
      <MoneyInput value={data.amount} onChange={v => setData({ amount: v as number })} {...restProps} />
    </div>
  )
}

const createPreviewIconAsUploadProp = (getUrl: (v: UploadFile) => Promise<string | undefined>) => {
  return {
    renderIcon: (v: UploadFile) =>
      // 只有图片和PDF文件才显示预览按钮
      /(png|jpe?g|pdf)$/.test(v.name || '') && (
        <Button
          type="text-subtle"
          size="xSmall"
          icon={<Eye />}
          onClick={async () => {
            const url = await getUrl(v)
            url && openFile(url)
          }}
        />
      ),
  }
}

const createOnDownloadAsUploadProp = (getUrl: (v: UploadFile) => Promise<string | undefined>) => {
  return {
    onDownload: async (v: UploadFile) => {
      const url = await getUrl(v)
      url && downloadFile(url, v.name)
    },
  }
}

export type FilePreviewAndDownloadServices = {
  getPreviewUrl?: (data: { dataId: string; fileId: number }) => Promise<string | undefined>
  getDownloadUrl?: (data: { dataId: string; fileId: number }) => Promise<string | undefined>
}
export type FileUploadServices = {
  requestUploadFile: (v: {
    fileName?: string
    fileSize?: number
    id?: string
  }) => Promise<defs.FileRequestUploadDto | undefined>
  completeUploadFile: (v: { token?: string }) => Promise<defs.FileCompleteUploadDto | undefined>
}

const FILE_MAX_SIZE = 1024 * 1024 * 1024 * 1
export type UploadFileObject = { id: number; name: string; size: number }
export type FileUploadProps = Omit<UploadProps, 'onChange'> & {
  dataId: string
  defaultValue?: Partial<UploadFileObject> | Partial<UploadFileObject>[]
  onChange?: (v: UploadFileObject | UploadFileObject[]) => void
}
export const FileUpload = (props: FileUploadProps & FileUploadServices & FilePreviewAndDownloadServices) => {
  const {
    className,
    dataId,
    fileSizeLimit = FILE_MAX_SIZE,
    defaultValue = [],
    onChange,
    requestUploadFile,
    completeUploadFile,
    getPreviewUrl,
    getDownloadUrl,
    ...restProps
  } = props
  const vars = useRCVars(() => {
    const _defaultValue = (Array.isArray(defaultValue) ? defaultValue : [defaultValue]).filter(Boolean)
    const defaultFileList = _defaultValue.map<UploadFile>(v => ({ uid: `${v.id}`, status: 'done', ...v }))
    const fileMap = defaultFileList.reduce((r, v) => ({ ...r, [v.uid]: v }), {} as Record<string, UploadFile>)
    return {
      defaultFileList,
      fileMap: fileMap as Record<string, UploadFile & { id: number; token: string; completed: boolean }>,
    }
  })

  const getAction = async (file: BrFile) => {
    const uploadInfo = await requestUploadFile({
      id: dataId,
      fileName: file.name,
      fileSize: file.size,
    })
    const { token, uploadUrl } = uploadInfo || {}

    if (!uploadUrl) throw new Error('UploadUrl is null')

    vars.fileMap[file.uid] = { uid: file.uid, id: 0, name: file.name, size: file.size, token: token!, completed: false }

    return uploadUrl
  }

  const doChange = async (v: UploadChangeParam) => {
    // 找出上传成功的文件
    const succeedFiles = v.fileList.filter(t => t.status === 'done').map(t => vars.fileMap[t.uid])

    // 还没有通知filepicker已完成上传任务的，需要通知一下
    for (let i = 0, file; i < succeedFiles.length; i++) {
      file = succeedFiles[i]
      if (!file.completed && file.token) {
        file.completed = true
        const res = await completeUploadFile({ token: file.token })
        res && (file.id = res.id!)
      }
    }

    const changedFiles = succeedFiles.map(v => ({ id: v.id, name: v.name!, size: v.size! }))
    // 通知上层当前上传成功的文件信息
    onChange?.(restProps.limit === 1 ? changedFiles[0] : changedFiles)
  }

  const onError = (e: Error) => {
    Message.warn(
      e.message === 'Size Limit'
        ? I18N.template(I18N.auto.zhiZhiChiMYi, { val1: getFileSizeUnit(fileSizeLimit) })
        : e.message === 'Exceed Limit'
          ? I18N.template(I18N.auto.zhiNengShangChuanGe, { val1: restProps.limit })
          : e.message
    )
  }

  return (
    <Upload
      className={bem('upload', [className])}
      action={getAction}
      urlPath={() => ''}
      // s3上传得Blob或者File对象包装一下，后者可以定义名称
      beforeUpload={v => new File([v], v.name)}
      format="raw"
      method="PUT"
      defaultFileList={vars.defaultFileList}
      fileSizeLimit={fileSizeLimit}
      showRemoveIconWhenError
      onError={onError}
      onChange={doChange}
      {...(getPreviewUrl
        ? createPreviewIconAsUploadProp(v => getPreviewUrl({ dataId, fileId: vars.fileMap[v.uid].id }))
        : {})}
      {...(getDownloadUrl
        ? createOnDownloadAsUploadProp(v => getDownloadUrl({ dataId, fileId: vars.fileMap[v.uid].id }))
        : {})}
      {...restProps}
    />
  )
}

export type DataAttachmentsProps = {
  className?: string
  dataId: string
  attachments?: { id?: number; name?: string; size?: number; url?: string }[]
  emptyText?: string
}
export const DataAttachments = (props: DataAttachmentsProps & FilePreviewAndDownloadServices) => {
  const { className, dataId, attachments: propAttachments, emptyText, getPreviewUrl, getDownloadUrl } = props

  const attachments = propAttachments?.filter(v => v.id || v.url)

  if (!attachments?.length) {
    return emptyText ? <Empty imageNode={<ErrorImage />} text={emptyText} /> : <>-</>
  }

  return (
    <>
      {attachments.map(v => (
        <Upload
          key={v.id}
          className={bem('attachment', [className])}
          fileList={[
            {
              uid: `${v.id}`,
              status: 'done',
              name: v.name,
              size: v.size,
            },
          ]}
          limit={1}
          showRemoveIcon={false}
          {...(getPreviewUrl ? createPreviewIconAsUploadProp(() => getPreviewUrl({ dataId, fileId: v.id! })) : {})}
          {...(getDownloadUrl ? createOnDownloadAsUploadProp(() => getDownloadUrl({ dataId, fileId: v.id! })) : {})}
        />
      ))}
    </>
  )
}

export const StringDatePicker = (props: { value?: string; onChange?: (v: string) => void }) => {
  const { value, onChange } = props
  return (
    <DatePicker
      value={value ? dayjs(value) : undefined}
      onChange={v => {
        onChange?.(v?.format('YYYY-MM-DD'))
      }}
    />
  )
}

export const YearPicker = (props: { value?: number; onChange?: (v: number) => void }) => {
  const { value, onChange } = props
  return (
    <DatePicker
      picker="year"
      disabledDate={v => v.year() > dayjs().year()}
      value={dayjs(`${value}`, 'YYYY')}
      onChange={v => onChange?.(v?.year())}
    />
  )
}

export const StringInputDatePicker = (
  props: Omit<DatePickerProps, 'value'> & { value?: string | string[]; checkable?: boolean }
) => {
  const { checkable, range, value: propValue, onChange, ...rest } = props
  const vars = useRCVars({ draftDateValue: undefined as dayjs.Dayjs | dayjs.Dayjs[] | undefined })

  const value = useRCComputed(() => {
    if (propValue === undefined) return range ? [] : propValue
    return Array.isArray(propValue) ? propValue.map(dayjs) : dayjs(propValue)
  }, [propValue])

  const checked = useRCComputed(() => {
    if (!value) return false
    return (Array.isArray(value) ? value[1]?.year() : value.year()) === 2099
  }, [propValue])

  return (
    <div className={bem('si-datepicker')}>
      <DatePicker
        range={range}
        value={checked ? undefined : value}
        onChange={(v, formatString) => {
          onChange?.((vars.draftDateValue = v), formatString)
        }}
        {...rest}
        disabled={checked}
      />

      {checkable && (
        <Checkbox
          checked={checked}
          onChange={e => {
            if (e.target.checked) {
              vars.draftDateValue = value
              onChange?.(
                (Array.isArray(value)
                  ? [value[0] || dayjs(), dayjs('2099-12-31', 'YYYY-MM-DD')]
                  : dayjs('2099-12-31', 'YYYY-MM-DD')) as dayjs.Dayjs,
                'YYYY-MM-DD'
              )
            } else {
              onChange?.(vars.draftDateValue as dayjs.Dayjs, 'YYYY-MM-DD')
            }
          }}>
          {I18N.auto.yongJiuYouXiao}
        </Checkbox>
      )}
    </div>
  )
}

export const DataLink = (props: {
  className?: string
  href?: string
  name?: ReactNode
  children?: ReactNode
  underline?: boolean
}) => {
  const { className, href, name, children, underline = true } = props
  if (!href) return <>{name || children}</>

  return (
    <Link className={bem('link', { underline }, [className])} type="primary" href={href} target="_blank">
      {name || children || href}
    </Link>
  )
}

export const PoPageLink = (props: {
  orderId?: string
  isUpdate?: boolean
  linePin?: boolean
  children?: ReactNode
}) => {
  const { orderId, linePin, children } = props
  if (!orderId) return null
  const lineNo = linePin && orderId.match(/-(\d$)/)?.[1]
  return (
    <DataLink
      href={`/order/show?id=${orderId.replace(/-.*$/, '')}${lineNo ? `&lineNo=${lineNo}` : ''}`}
      underline={!children}>
      {children || orderId}
    </DataLink>
  )
}

// 税率小数百分比计算是会精度问题，如：0.07 * 100 = 7.000000001，所以这里以正则字符串转换一下
// 如: 0.01 -> 1% 0.1 -> 10% 0.124 -> 12.4%
export const getTaxRateText = (v?: number) => {
  return v !== undefined ? `${+`${v}00`.replace(/^0\.(\d\d)/, '$1.')}%` : v
}

export const DataTaxRate = (props: { value?: number }) => {
  if (props.value === undefined) return '-'
  return getTaxRateText(props.value)
}

export const DataForm = (props: FormProps) => {
  const { className, ...rest } = props
  return <Form className={bem('fm', [className])} layout="vertical" autoComplete="off" {...rest} />
}

export const DataFormItem = (
  props: FormItemProps & {
    w100?: boolean
    w667?: boolean
    w500?: boolean
    disableLabel?: boolean
    children?: ReactElement
  }
) => {
  const {
    className,
    w100 = false,
    w667 = false,
    w500 = false,
    disableLabel = false,
    required,
    rules = [],
    validateTrigger,
    ...rest
  } = props

  return (
    <Form.Item
      className={bem('fm-item', { w100, w667, w500, disableLabel }, [className])}
      validateFirst
      required={required}
      rules={[
        // 这bedrock组件库Input和Select触发校验的交互不一样，就这样吧
        ...(required
          ? [{ required: true, message: I18N.auto.ciXiangBiTian, ...(validateTrigger ? { validateTrigger } : {}) }]
          : []),
        ...rules,
      ]}
      {...rest}
    />
  )
}

export const useFormLeavePagePrompt = (props: { title?: string } = {}) => {
  const [canLeave, setCanLeave] = useState(true)

  usePrompt(
    () =>
      new Promise<boolean>(resolve => {
        Modal.confirm({
          title: props.title || I18N.auto.queDingLiKaiYe,
          content: I18N.auto.liKaiYeMianDang,
          onOk: () => resolve(true),
          onCancel: () => resolve(false),
        })
      }),
    !canLeave
  )

  return { setCanLeave }
}

export const CurrencySelect = (props: SelectProps) => {
  const { data } = useService(bizService.commonCurrencyListCurrency)
  return (
    <Select
      dropdownClassName={bem('csdd')}
      // @ts-ignore
      options={data}
      fieldNames={{ value: 'name' }}
      showSearch="inner"
      {...props}
    />
  )
}

export const AsyncButton = (props: Omit<ButtonProps, 'loading' | 'onClick'> & { onClick?: () => Promise<void> }) => {
  const { onClick, ...rest } = props
  const [loading, setLoading] = useState(false)

  return (
    <Button
      onClick={async () => {
        try {
          setLoading(true)
          await onClick?.()
        } finally {
          setLoading(false)
        }
      }}
      loading={loading}
      {...rest}
    />
  )
}
export const ValueCopyView = (props: { value?: string | number; light?: boolean }) => {
  const { value, light = false } = props
  if (!value) return '-'
  return (
    <div className={bem('copy', { light: light })}>
      {value}
      <CopyToClipboard
        text={value}
        onCopy={() => {
          Message.success(I18N.auto.fuZhiChengGong)
        }}>
        <Copy size={16} />
      </CopyToClipboard>
    </div>
  )
}

export const ListMoneyView = (props: { value?: defs.Money | defs.MoneyDto; className?: string }) => {
  const { value, className } = props
  if (props.value === undefined) return <>-</>
  const money = value?.amount !== undefined ? formatMoenyAmount(value) : ''
  return <div className={bem('lmv', [className])}>{`${value?.currency || ''} ${money}`}</div>
}

export const CompanyView = (props: { companyName?: string; companyCode?: string }) => {
  const { companyName, companyCode } = props
  if (!companyName) return '-'
  return (
    <div className={bem('comv')}>
      <div className={bem('comv-name')}>{companyName}</div>
      {companyCode && <div className={bem('comv-code')}>{companyCode}</div>}
    </div>
  )
}

export const renderPeriod = (period?: string | number) =>
  period === undefined ? I18N.auto.zanWu : ['-1', -1].includes(period) ? I18N.auto.yongJiu : period
