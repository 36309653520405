import type { ReactNode } from 'react'
import { BEMGenerator } from '@oahz/neact-utils'
import { useCountDown } from 'ahooks'
import { map } from 'lodash'
import I18N from '@feature/i18n'
import './style.less'

const bem = BEMGenerator('ftdvc-time-down')

export const TimeCountDown = (props: {
  id?: string
  label?: ReactNode
  targetDate: Date | number | string | undefined
  onEnd?: () => void
  interval?: number
  type?: 'normal' | 'bidding' | 'light' | 'bid-clarification'
  className?: string
}) => {
  const { targetDate, onEnd, className, label, id, interval = 1000, type = 'normal' } = props
  const [countdown, formattedRes] = useCountDown({
    targetDate,
    onEnd,
    interval,
  })

  //格式化时间内容做显示用
  const formateDay = () => {
    const { days, hours, minutes, seconds, milliseconds } = formattedRes
    //当前剩余时间seconds
    const _countdown = Math.round(countdown / 1000)
    //最大显示时间
    const maxDays = 60 * 60 * 24 * 100

    //当前时间大于最大显示时间
    const showMaxDays = _countdown >= maxDays

    //TODO 下面放方法，可以直接再返回位置处理1.判断是否最大返回固定内容，2.在返回位置统一方法转字符串，目前写法因为不确定是否还会有其他转义操作
    const getDays = () => {
      let _day = showMaxDays ? 99 : days
      return _day.toString()
    }
    const getHours = () => {
      let _hours = showMaxDays ? 23 : hours
      return _hours < 10 ? `0${_hours}` : _hours.toString()
    }
    const getMinutes = () => {
      let _minutes = showMaxDays ? 59 : minutes
      return _minutes < 10 ? `0${_minutes}` : _minutes.toString()
    }
    const getSeconds = () => {
      let _seconds = showMaxDays ? 59 : seconds
      return _seconds < 10 ? `0${_seconds}` : _seconds.toString()
    }
    const getMilliseconds = () => {
      let _milliseconds = showMaxDays ? 99 : Math.floor(milliseconds / 10)
      const _res = _milliseconds < 10 ? `0${_milliseconds}` : _milliseconds.toString()
      return _res
    }
    return {
      days: getDays(),
      hours: getHours(),
      minutes: getMinutes(),
      seconds: getSeconds(),
      milliseconds: getMilliseconds(),
    }
  }

  const ShowItem = (props: { value: string; unit: string; isDays?: boolean }) => {
    const { value, unit } = props
    return (
      <div className={bem(`${type}-item`)}>
        {map(value, item => {
          return <div className={bem(`${type}-item-time`)}>{item}</div>
        })}
        <span>{unit}</span>
      </div>
    )
  }

  const { days, hours, minutes, seconds, milliseconds } = formateDay()

  if (type === 'normal' || type === 'light') {
    return (
      <div id={id} className={bem(type, [className])}>
        {label}
        <ShowItem value={days} unit={I18N.auto.tian_2} />
        <ShowItem value={hours} unit={I18N.auto.shi_2} />
        <ShowItem value={minutes} unit={I18N.auto.fen} />
        <ShowItem value={seconds} unit={I18N.auto.miao} />
      </div>
    )
  }

  return (
    <div id={id} className={bem('bidding', [className])}>
      <div className={bem('bidding-title')}>
        <div className={bem('bidding-title-line')}></div>
        <div className={bem('bidding-title-text')}>{label || I18N.auto.juJieShuHaiSheng}</div>
        <div className={bem('bidding-title-line')}></div>
      </div>
      <div className={bem('bidding-time')}>
        <span>{days}</span>
        {I18N.auto.tian_2}
        <span>{hours}</span>
        {I18N.auto.shi_2}
        <span>{minutes}</span>
        {I18N.auto.fen}
        <span className={bem('bidding-time-second')}>
          {type === 'bid-clarification' ? seconds : `${seconds}.${milliseconds}`}
        </span>
        {I18N.auto.miao}
      </div>
    </div>
  )
}
