import { ReactNode, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { BEMGenerator } from '@oahz/neact-utils'
import { ELightSelect } from '@bedrock-e/common'
import Select, { Options } from '@bedrock/components/lib/Select'
import { useManualService, useService } from '@oahz/neact'
import { useRCMount, useRCVars } from '@oahz/neact-utils'
import { useDebouncedCallback } from 'use-debounce'
import {
  DataAttachments,
  DataAttachmentsProps,
  FilePreviewAndDownloadServices,
  FileUpload,
  FileUploadProps,
  ValueCopyView,
} from '@feature/views'
import { Button, Form, InputNumber } from '@bedrock/components'
import { Upload } from '@bedrock/icons-react'
import I18N from '@feature/i18n'
import Tag, { TagEnumMap } from '@/components/tag'
import { apiService, bizEnums, defs } from '@/services'
import { DataLink, DataMoney } from '@/components/data-view'
import './style.less'

const bem = BEMGenerator('co')

export const OrderPageLink = (props: { orderId: string; children?: string }) => {
  const { orderId, children } = props
  if (!orderId && !children) return <>-</>
  return <DataLink href={`/order/show?id=${orderId}`}>{children || orderId}</DataLink>
}

export const DeliveryPageLink = (props: { deliveryId: string; children?: string }) => {
  const { deliveryId, children } = props
  if (!deliveryId && !children) return '-'
  return <DataLink href={`/order/delivery-show?id=${deliveryId}`}>{children || deliveryId}</DataLink>
}

export const ELightAsyncSearchSelect = (
  props: Parameters<typeof ELightSelect>[0] & {
    service: (...args: any) => Promise<any>
    className?: string
    value?: number[]
    onChange?: (value?: number[]) => void
  }
) => {
  const { className, value, onChange, service, fieldNames, ...resetProps } = props
  const [visible, setVisible] = useState(false)
  const [options, setOptions] = useState<any[]>([])
  const [initFlag, setInitFlag] = useState(false)
  const { data, loading, request } = useManualService(service)
  const vars = useRCVars(() => ({ params: { current: 1, limit: 50, keyword: undefined } as any, hasMore: false }))
  useEffect(() => {
    vars.hasMore = !!data?.hasMore
    if (vars.params.current === 1) {
      setOptions(data?.records || [])
    } else {
      setOptions(prev => prev.concat(data?.records || []))
    }
  }, [data])

  useEffect(() => {
    if (initFlag || !options.length) return
    if (!value) {
      setInitFlag(true)
      return
    }
    let hasValNoOption = false
    let chaeckKey = fieldNames?.value ? fieldNames.value : 'value'
    value?.forEach(v => {
      if (!options.find(o => o[chaeckKey] === v)) {
        hasValNoOption = true
      }
    })
    if (hasValNoOption) {
      request((vars.params = { ...vars.params, current: vars.params.current + 1, limit: 50 }))
    } else {
      setInitFlag(true)
    }
  }, [options, value, initFlag])

  useRCMount(() => request((vars.params = { current: 1, limit: 50 })))

  useEffect(() => {
    setTimeout(async () => {
      const dom = document.getElementsByClassName('cqf__assd')[0]
      const scrollEl = dom && dom.getElementsByClassName('rock-scrollbar-view')[0]
      if (!dom || !visible) {
        setTimeout(() => scrollEl?.scrollTo({ top: 0 }), 100)
        return
      }

      if (!scrollEl) return
      const clientHeight = scrollEl.clientHeight
      const fn = () => {
        const buf = scrollEl.scrollHeight - scrollEl.scrollTop - clientHeight < 1
        if (vars.hasMore && buf && !loading) {
          request((vars.params = { ...vars.params, current: vars.params.current + 1, limit: 50 }))
        }
      }
      scrollEl?.addEventListener('scroll', fn)
      // return () => scrollEl?.removeEventListener('scroll', fn)
    })
  }, [visible])

  const onSearch = useDebouncedCallback(async (keyword?: string) => {
    // if (!visible) return //不知道为什么关闭dropdown会触发onsearch，这里return一下
    console.log('keyword', keyword)
    request((vars.params = { current: 1, limit: 50, keyword }))
  }, 200)

  return (
    <ELightSelect
      {...resetProps}
      value={value}
      multiple
      visible={visible}
      // virtual
      dropdownClassName={bem('assd')}
      options={(options || []) as Options}
      showSearch
      onSearch={onSearch}
      onChange={onChange}
      fieldNames={fieldNames}
      onOpenChange={e => {
        setVisible(e)
        console.log('e', e)
      }}
    />
  )
}

const OrderLineDeliveryView = (props: { label: string; value: string | number | ReactNode }) => {
  const { label, value } = props
  return (
    <div className={bem('oldv')}>
      <div className={bem('oldv-label')}>{label}</div>
      <div className={bem('oldv-value')}>{value}</div>
    </div>
  )
}

export const OrderLineDeliveryCount = (props: { data: defs.FmOrderDetailLineDto }) => {
  const { data } = props
  const { orderMethod } = data
  return orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY ? (
    <OrderLineDeliveryView label="已发货数量：" value={data.shippedQuantity} />
  ) : (
    <OrderLineDeliveryView label="已履约金额：" value={<DataMoney size="small" value={data.shippedAmount} />} />
  )
}

export const POValueCopyView = (props: { orderNo?: string; className?: string }) => {
  return (
    <div className={bem('copy', [props.className])}>
      PO单号：
      <ValueCopyView value={props.orderNo} light>
        <OrderPageLink orderId={props.orderNo!} />
      </ValueCopyView>
    </div>
  )
}

export const DeliveryLineGoodsInfo = (props: {
  value: { goodsInfo?: string; brand?: string; configuration?: string }
}) => {
  const { value } = props
  const { goodsInfo, brand, configuration } = value
  if (!goodsInfo) return <>-</>
  return (
    <div className={bem('dlgi')}>
      <div className={bem('dlgi-name')} title={goodsInfo}>
        {goodsInfo}
      </div>
      <div className={bem('dlgi-item')}>品牌：{brand}</div>
      <div className={bem('dlgi-item')}>配置：{configuration}</div>
    </div>
  )
}

export const ShipUpload = (props: Omit<FileUploadProps, 'dataId'>) => {
  return (
    <FileUpload
      {...props}
      dataId=""
      requestUploadFile={apiService.fmFmRequestUploadFile}
      completeUploadFile={apiService.fmFmCompleteUploadFile}
      getDownloadUrl={apiService.fmFmGetAttachmentDownloadUrl}
      trigger={
        <Button type="checked" prefix={<Upload />}>
          {I18N.auto.shangChuanFuJian}
        </Button>
      }
    />
  )
}

const poFilePreviewAndDownloadServices: FilePreviewAndDownloadServices = {
  getPreviewUrl: v => apiService.fmFmGetAttachmentPreviewUrl({ fileId: v.fileId }),
  getDownloadUrl: v => apiService.fmFmGetAttachmentDownloadUrl({ fileId: v.fileId }),
}

export const PoAttachments = (props: DataAttachmentsProps) => {
  return <DataAttachments {...props} {...poFilePreviewAndDownloadServices} />
}

export const OrderLineHeader = (props: { title: string; status: defs.FmOrderLineStatus }) => {
  const { title, status } = props
  return (
    <div className={bem('ol-header')}>
      <div>{title}</div>
      <Tag status={status} statusMap={TagEnumMap.FmOrderLineStatusEnum} />
    </div>
  )
}

export const OrderLineAnchorTitleView = (props: { data: defs.FmOrderDetailLineDto }) => {
  const { data } = props
  const isQuantity = data.orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY
  return (
    <div className={bem('olatv')}>
      <div className={bem('olatv-title')}>
        {`L${data.lineNo?.split('-')[1]} : `}
        {data.goodsName}
      </div>
      <div className={bem('olatv-info')}>
        <div className={bem('olatv-info-label')}>{isQuantity ? '已发货数量' : '履约金额'}</div>
        <div className={bem('olatv-info-value')}>
          {isQuantity ? (
            data.shippedQuantity
          ) : (
            <DataMoney size="xsmall" fontWeight="extraBold" highlight={false} value={data.shippedAmount} />
          )}
        </div>
      </div>
    </div>
  )
}

export const QueryCreatorIds = (props: { value?: string[]; onChange?: (v: string[]) => void }) => {
  const { data: creatorsOpts } = useService(apiService.fmFmCreatorList)
  return <ELightSelect options={creatorsOpts || []} fieldNames={{ value: 'id' }} showSearch {...props} />
}

export const CourierCompanySelect = (props: { value?: string[]; onChange?: (v: string[]) => void }) => {
  const { data: companyOpts } = useService(apiService.fmFmListCourierCompanies)
  return <Select options={companyOpts || []} fieldNames={{ value: 'id' }} showSearch {...props} />
}

export const formatOrderTime = (time?: string) => (time ? dayjs(time).format('YYYY-MM-DD HH:mm:ss Z') : '-')

export const CreateDeliveryAmount = (props: {
  shippingAmount: defs.MoneyDto
  value?: defs.Money
  onChange?: (v?: defs.Money) => void
}) => {
  const { shippingAmount, value, onChange } = props
  const max = shippingAmount?.amount
  const toFixed = ['JPY', 'KRW'].includes(shippingAmount?.currency!) ? 0 : 2
  return (
    <InputNumber
      value={value?.amount}
      min={0}
      max={max}
      toFixed={toFixed}
      addonAfter={null}
      direction="vertical"
      onChange={e =>
        onChange?.(
          typeof e === 'number' && e > 0 ? { amount: Number(e), currency: shippingAmount.currency } : undefined
        )
      }
    />
  )
}

const genCreateDeliveryKey = (orderId: string) => `${Date.now()}_${orderId}`
export const setDeliveryStorageData = (orderId: string, lineIds: string[], orderNo?: string) => {
  const key = genCreateDeliveryKey(orderId)
  const data = {
    orderId: orderId,
    lineIds: lineIds,
    orderNo: orderNo,
  }
  localStorage.setItem(key, JSON.stringify(data))
  return key
}
export const getDeliveryStorageData = (key: string) => {
  const storedData = localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)!)
  return storedData || undefined
}
export const removeStorageData = (key: string) => localStorage.removeItem(key)

const ShipAcceptanceStatusFieldView = (props: { label: string; value?: ReactNode | string }) => {
  const { label, value } = props
  return (
    <div className={bem('qsfv')}>
      <div className={bem('qsfv-label')}>{label}</div>
      <div className={bem('qsfv-value')}>{value || '-'}</div>
    </div>
  )
}

export const ShipAcceptanceStatusView = (props: { data?: defs.FmDeliveryFulfillmentDetailDto }) => {
  const { data = {} } = props
  return (
    <div className={bem('qsv', [bem(`qsv-${data.acceptanceStatus}`)])}>
      <div className={bem('qsv-l')}>
        <div className={bem('qsv-l-status')}>
          {!!data.acceptanceStatus && bizEnums.AcceptanceStatusEnum.pick(data.acceptanceStatus)?.name}
        </div>
        <div className={bem('qsv-l-field')}>
          <ShipAcceptanceStatusFieldView label="发货单号" value={<ValueCopyView value={data.id} />} />
        </div>
      </div>
    </div>
  )
}

export const DeliveryListAcceptanceStatus = (props: { status?: defs.AcceptanceStatus }) => {
  const { status } = props
  if (!status) return <>-</>
  return <Tag status={status} statusMap={TagEnumMap.AcceptanceStatusEnum} />
}
