import { type ReactNode, useState } from 'react'
import NoData from '@bedrock/components/es/Empty/images/large/NoData'
import { AppDefaultTableHeader } from '@feature/views'
import type { ELightQueryItem } from '@bedrock-e/common/lib/types/components/ELightQuery/types'
import { BEMGenerator, useRCMount } from '@oahz/neact-utils'
import { Pagination, Spin, Empty, Scrollbar } from '@bedrock/components'
import './style.less'

const bem = BEMGenerator('cwb')

export const ListFieldLable = (props: { label: string }) => <div className={bem('lfl')}>{props.label}</div>

export const ListFieldView = (props: { label: string; value?: string | ReactNode }) => (
  <div className={bem('lfv')}>
    <ListFieldLable label={props.label} />
    <div className={bem('lfv-v')} title={typeof props.value === 'string' ? props.value || '-' : undefined}>
      {props.value || '-'}
    </div>
  </div>
)

export const WorkbenchListItem = (props: {
  header: ReactNode
  footer?: ReactNode
  children?: ReactNode
  className?: string
}) => {
  const { header, footer, children, className } = props
  return (
    <div className={bem('li', [className])}>
      {header && <div className={bem('li-header', [className])}>{header}</div>}
      <div className={bem('li-content', [className])}>{children}</div>
      {footer && <div className={bem('li-footer', [className])}>{footer}</div>}
    </div>
  )
}
export const DiyContent = (props: { children: ReactNode; loading: boolean; total?: number }) => {
  const { children, loading, total } = props

  if (loading)
    return (
      <div className={bem('content-fill')}>
        <Spin />
      </div>
    )

  if (total)
    return (
      <div className={bem('table-list')}>
        <Scrollbar>{children}</Scrollbar>
      </div>
    )

  return (
    <div className={bem('content-fill')}>
      <Empty
        className={bem('empty')}
        imageNode={<NoData />}
        text={<span className={bem('empty-text')}>{'表格内容为空'}</span>}
      />
    </div>
  )
}

export const DiyTable = (props: {
  queryItems: ELightQueryItem[]
  children: ReactNode
  request: (params: any) => void
  total?: number
  placeholder?: string
  loading: boolean
}) => {
  const { queryItems, children, request, total, placeholder, loading } = props
  const [{ current, limit }, setPagination] = useState({ current: 1, limit: 10 })
  const [preFilter, setPreFilter] = useState({})

  const filterChange = (filterParams: any) => {
    setPreFilter(filterParams)
    request?.({ ...filterParams, current, limit })
  }

  const paginationChange = (current: number, pageSize: number) => {
    let _current = current
    if (limit !== pageSize) _current = 1
    setPagination({ current: _current, limit: pageSize })
    request?.({ ...preFilter, current, limit: pageSize })
  }

  useRCMount(() => {
    request?.({
      current,
      limit,
    })
  })

  return (
    <div className={bem('diy-table')}>
      <AppDefaultTableHeader
        onQuery={filterChange}
        queryItems={queryItems}
        actions={<div />}
        queryInput={{
          placeholder: placeholder || '请输入关键字搜索',
        }}
      />
      <DiyContent loading={loading} total={total}>
        {children}
      </DiyContent>
      {total ? (
        <Pagination
          className={bem('pagination')}
          current={current}
          pageSize={limit}
          total={total}
          isShowTotal
          isShowJumper
          showSizeChanger
          onChange={paginationChange}
        />
      ) : null}
    </div>
  )
}
