import { useState, ReactNode } from 'react'
import { Line } from '@ant-design/charts'
import { BEMGenerator, useBoolean, useRCWatch, useRCUpdate, useRCMount } from '@oahz/neact-utils'
import { ColumnsType } from '@bedrock/components/es/Table'
import { Timeline, Select, Scrollbar, Tabs, Button, Spin, Modal, Table } from '@bedrock/components'
import { useInterval } from 'ahooks'
import { useService, useManualService } from '@oahz/neact'
import { map } from 'lodash'
import { formatDateTime } from '@feature/shared'
import { ClarifyModal, ClarifyShow } from '@feature/views'
import { isEqual } from 'lodash'
import { CompanyView, getTaxRateText, DataStatus } from '@/components/data-view'
import { QutotationAttachments } from '@/components/quotation'
import { apiService, defs, bizEnums } from '@/services'
import './style.less'

const bem = BEMGenerator('line-chars')

export const CardItem = (params: { label: ReactNode; value?: ReactNode; children?: ReactNode }) => {
  const { label, value, children } = params
  return (
    <div className={bem('card-detail-item')}>
      <div className={bem('card-detail-item-lable')}>{label}</div>
      <div className={bem('card-detail-item-value')}>{value || children || '-'}</div>
    </div>
  )
}

export const DetailCard = (params: {
  children: ReactNode
  title?: ReactNode
  className?: string
  action?: ReactNode
}) => {
  const { title, children, className, action } = params
  return (
    <div className={bem('card-detail', [className])}>
      <div className={bem('card-detail-heaader')}>
        <div className={bem('card-detail-heaader-title')}>{title}</div>
        {action}
      </div>
      <div className={bem('card-detail-content')}>{children}</div>
    </div>
  )
}

//报价图表
export const Chemistry = (params: { id: string }) => {
  const { id } = params
  const { data: _data } = useService(() => apiService.quotationQuotationQueryChart({ id }))
  const [renderData, setRenderData] = useState([])
  const [options, setOption] = useState([])
  const [selectId, setSelectId] = useState('')
  const [currency, setCurrency] = useState('')

  //格式化图表显示数据
  const formaterRenderdata = (value: defs.QuotationHistoryDto[]) => {
    console.log(value, 'valuevaluevaluevalue')
    const _renderData =
      value?.map(item => {
        return { ...item, amount: item?.quotationAmount?.amount || 0 }
      }) || []
    const _currency = value?.[0]?.quotationAmount?.currency || ''
    setRenderData(_renderData)
    setCurrency(_currency)
  }

  //切换行
  const selectChange = (id, option) => {
    setSelectId(id)
    formaterRenderdata(option?.option?.data)
  }

  useRCUpdate(() => {
    if (_data) {
      const { isAllBid, totalQuotationHistory, lineQuotationHistories } = _data || {}
      if (isAllBid) {
        formaterRenderdata(totalQuotationHistory)
      } else {
        const _options =
          lineQuotationHistories?.map((item, index) => {
            return {
              name: `Line${index + 1}: ${item.quotationHistoryDTOList?.[0]?.goodsName || '-'}`,
              data: item.quotationHistoryDTOList,
              key: item.lineId,
              value: item.lineId,
            }
          }) || []
        setSelectId(_options?.[0]?.value)
        setOption(_options)
        formaterRenderdata(_options?.[0]?.data)
      }
    }
  }, [_data])

  if (!_data) return null

  const { isAllBid } = _data || {}

  console.log(renderData, 'renderData')

  const config = {
    data: renderData,
    height: 300,
    xField: 'createdAt',
    yField: 'amount',
    point: {
      shapeField: 'hollowCircle', //circle square 属性可以用但是头部标识不生效
      visiable: true,
      sizeField: 3,
    },

    // colorField: 'type',
    // color: ['#E09B27', '#38A636'],

    interaction: {
      tooltip: {
        marker: true, //影响鼠标hover是否显示样式
        series: false,
        shared: false,
        render: (e, options) => {
          const { title, items } = options
          const time = items.filter(v => v.name === 'createdAt')
          const amount = items.filter(v => v.name === 'amount')
          console.log(time, amount, 'chart')
          return (
            <div className={bem('tool')}>
              <div>
                <div>报价时间</div>
                <div>{time?.length && time[0].value ? formatDateTime(time[0].value, 'YYYY-MM-DD HH:mm:ss') : '-'}</div>
                <div>价格</div>
                <div>
                  {amount?.length && amount[0].value !== undefined
                    ? `${currency || ''} ${amount[0].value}`.replace(/\d{1,3}(?=(\d{3})+$)/g, s => `${s},`)
                    : '-'}
                </div>
              </div>
            </div>
          )
        },
      },
    },
    axis: {
      y: {
        line: true, //坐标轴
        grid: true, //网格线
        gridStroke: '#f1f1f1', //网格线颜色
        gridLineWidth: 1, //网格线宽度
        gridLineDash: [0, 0], //虚线间隔
        gridStrokeOpacity: 1, // 网格线透明度
        // labelFormatter: v => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, s => `${s},`),
      },
      x: {
        labelFormatter: (_, index) => {
          return `第${index + 1}次`
        },
      },
    },
  }

  return (
    <DetailCard title={'竞价排名'}>
      {!isAllBid ? (
        <Select
          value={selectId}
          options={options}
          className={bem('charts-search')}
          onChange={selectChange}
          // fieldNames={{ value: 'quoteLineId', name: 'goodsName' }}
        />
      ) : null}
      <Line {...config} />
    </DetailCard>
  )
}

//询价单详情
export const QuotationDetailCard = (params: {
  data?: defs.QuotationDetailDto
  isTender?: boolean
  quotationCurrency?: defs.Currency
}) => {
  const { data, isTender, quotationCurrency } = params
  return (
    <DetailCard title={'询价单详情'}>
      <CardItem label={'询价标题'} value={data?.title} />
      <CardItem label={'询价单号'} value={data?.rfxId} />
      <CardItem label={'报价单号'} value={data?.id} />
      <CardItem
        label={'结算公司'}
        value={
          <CompanyView companyName={data?.settlementCompanyName} companyCode={data?.settlementCompanyCountryCode} />
        }
      />
      <CardItem label={'币种'} value={quotationCurrency} />
      <CardItem
        label={'支付方式'}
        value={data?.paymentMethod !== undefined && bizEnums.PaymentMethodEnum.pick(data?.paymentMethod)?.name}
      />

      <CardItem label={'账期'} value={data?.accountPeriod !== undefined ? `${data?.accountPeriod}天` : '-'} />
      {isTender ? (
        <>
          <CardItem label={'发起时间'} value={formatDateTime(data?.createdAt)} />
          <CardItem label={'结束时间'} value={formatDateTime(data?.deadlineAt)} />
        </>
      ) : (
        <CardItem label={'备注'} value={data?.rfxRemark} />
      )}
      <CardItem
        label="需求附件"
        value={<QutotationAttachments className={bem('attachment')} dataId={''} attachments={data?.attachments} />}
      />
    </DetailCard>
  )
}

//竞价规则
export const BiddingRuleCard = (params: { data?: defs.QuotationRuleDto }) => {
  const { data } = params
  if (!data) return null

  //调整幅度
  const showAdjustStepType = () => {
    if (data?.adjustStep?.adjustStepType === bizEnums.QuotationRuleAdjustStepTypeEnum.BY_AMOUNT) {
      return data?.adjustStep?.amount
        ? `${data?.adjustStep?.amount?.currency} ${data?.adjustStep?.amount?.amount}`
        : '-'
    } else {
      return getTaxRateText(data?.adjustStep?.proportion) || '-'
    }
  }

  return (
    <DetailCard title={'竞价规则'}>
      <CardItem label={'竞价模式'} value={bizEnums.QuotationRuleBiddingModeEnum.pick(data?.biddingMode!)?.name} />
      <CardItem
        label={'调价步幅'}
        value={bizEnums.QuotationRuleAdjustStepTypeEnum.pick(data?.adjustStep?.adjustStepType!)?.name}
      />
      <CardItem label={data.isAllBid ? '调价幅度' : '调价幅度 (单价)'} value={showAdjustStepType()} />
      <CardItem
        label={'竞价规则'}
        value={bizEnums.QuotationRuleBiddingRegulationEnum.pick(data?.biddingRegulation!)?.name}
      />
      <CardItem label={'报价间隔时长'} value={data.delayTime ? `${data.delayTime}分钟` : '不限'} />
      <CardItem label={'自动延时时长'}>
        {data.autoDelayTime?.isDelay
          ? `最后${data.autoDelayTime?.lastDelayTime}分钟出现第一名延时${data.autoDelayTime?.delayTime}分钟`
          : '无延时'}
      </CardItem>
    </DetailCard>
  )
}

const HistoryTable = (props: { id?: string }) => {
  const { id } = props
  const [visible, [show, hide]] = useBoolean(false)
  const { data, loading, request } = useManualService(apiService.quotationTenderRevisionHistoryList)

  const onClose = () => hide()

  useRCUpdate(() => {
    if (visible) {
      request({ id: id! })
    }
  }, [visible])

  const columns: ColumnsType<defs.QuotationRevisionHistoryListDto> = [
    {
      dataIndex: 'createdAt',
      title: '操作时间',
      width: 180,
      align: 'left',
      render: v => formatDateTime(v),
    },
    {
      dataIndex: 'operator',
      title: '操作人',
      width: 240,
      align: 'left',
      render: val => `${val?.name || ''}${val?.mail ? ` (${val.mail})` : ''}`,
    },
    {
      dataIndex: 'quotationLineId',
      title: '行号',
      width: 90,
      align: 'left',
      render: v => (v ? `Line ${v.split('-')[1]}` : '-'),
    },
    {
      dataIndex: 'oldValue',
      title: '变更前',
      width: 240,
      align: 'left',
      render: (v, record) => {
        let _value = v
        if (record.fieldName === '发票类型') _value = bizEnums.InvoiceTypeEnum.pick(v)?.name
        if (record.fieldName === '商品名称') return _value || '-'
        return `${record.fieldName}: ${_value || '-'}`
      },
    },
    {
      dataIndex: 'newValue',
      title: '变更后',
      width: 240,
      align: 'left',
      render: (v, record) => {
        let _value = v
        if (record.fieldName === '发票类型') _value = bizEnums.InvoiceTypeEnum.pick(v)?.name
        if (record.fieldName === '商品名称') return _value || '-'
        return `${record.fieldName}: ${_value || '-'}`
      },
    },
  ]

  return (
    <>
      <Button type="text" onClick={show}>
        查看详情
      </Button>
      <Modal className={bem('history-modal')} title={'变更详情'} visible={visible} onCancel={onClose} footer={null}>
        <Table className={bem('history-table')} columns={columns} data={data || []} scroll={{ x: true, y: '100px' }} />
      </Modal>
    </>
  )
}

//报价历史
export const HistoryLog = (params: { list?: defs.QuotationHistoryDto[]; id: string }) => {
  const { list, id } = params
  if (!list?.length) return null
  return (
    <DetailCard title={'报价历史'} className={bem('history')} action={<HistoryTable id={id} />}>
      <Scrollbar>
        <Timeline className={bem('history-log')}>
          {map(list, (item, index) => {
            return (
              <Timeline.Item key={`${index + 1}`} title={formatDateTime(item.createdAt)}>
                <div className={bem('history-log-content')}>
                  {item.operator?.name}（{item.operator?.mail}）
                </div>
                <div>{item.isTenderDiscarded ? '已弃标' : '提交了报价'}</div>
              </Timeline.Item>
            )
          })}
        </Timeline>
      </Scrollbar>
    </DetailCard>
  )
}

//轮询hooks
export const useCurrentdata = (quotationId: string, quotable?: boolean) => {
  const [deadlineInfo, setDeadlineInfo] = useState<defs.QuotationPollingInfoDto>({})

  useInterval(
    async () => {
      const res = await apiService.quotationQuotationPollingInfo({
        quotationId,
      })

      if (!isEqual(deadlineInfo, res)) setDeadlineInfo(res || {})
    },
    quotable ? 1000 : undefined
  )

  return { deadlineInfo }
}

export const TenderQuestion = (props: { questionId: string; rfxId?: string; canClarify?: boolean }) => {
  const { questionId, rfxId, canClarify } = props
  const [mylist, setMyList] = useState<defs.QuotationTenderQuestionDto[]>()
  const [otherList, setOtherList] = useState<defs.QuotationTenderQuestionDto[]>()
  const [type, setType] = useState<defs.QuotationQueryType>('MY')

  const onSubmit = (params: defs.QuestionTenderConsultationCommand) => {
    return apiService.quotationTenderConsultation({ id: questionId }, params)
  }
  const fetchMyList = async () => {
    const res = await apiService.quotationTenderQueryQuestionList({ rfxId: rfxId, type: 'MY' })
    setMyList(res)
  }

  useRCMount(async () => {
    fetchMyList()
  })

  useRCMount(async () => {
    const res = await apiService.quotationTenderQueryQuestionList({ rfxId: rfxId, type: 'OTHER' })
    setOtherList(res)
  })

  const renderItem = (value: defs.QuotationTenderQuestionDto, isMy: boolean, show: () => void) => {
    return (
      <div className={bem('question-item')} onClick={show}>
        {isMy ? (
          <DataStatus
            className={bem('question-item-tag', { [value?.questionStatus!]: true })}
            name={bizEnums.QuotationQuestionStatusEnum.pick(value?.questionStatus)?.name}
          />
        ) : (
          ''
        )}
        <div className={bem('question-item-title')}>{isMy ? value.consultation?.title : value.reply?.title}</div>
      </div>
    )
  }

  const renderButton = (show: () => void) => {
    return (
      <Button className={bem('question-btn')} onClick={show} type="checked-neutral" disabled={!canClarify}>
        我要咨询
      </Button>
    )
  }

  return (
    <DetailCard title={'澄清咨询'} className={bem('question')}>
      <Tabs className={bem('question-tabs')} defaultActiveKey="MY" onChange={setType}>
        <Tabs.TabPane tab={`我的咨询${mylist?.length ? `(${mylist?.length})` : ''}`} key="MY"></Tabs.TabPane>
        <Tabs.TabPane
          tab={`其他人咨询${otherList?.length ? `(${otherList?.length}) ` : ''}`}
          key="OTHER"></Tabs.TabPane>
      </Tabs>
      <div className={bem('question-list')}>
        <Scrollbar>
          {(type === 'MY' ? mylist : otherList)?.map((item, index) => {
            return (
              <ClarifyShow
                key={`clarify-${index}`}
                data={item}
                title={type === 'OTHER' ? '招标澄清' : '澄清咨询'}
                hideConsultation={type === 'OTHER'}
                renderItem={show => renderItem(item, type === 'MY', show)}
              />
            )
          })}
        </Scrollbar>
      </div>

      <ClarifyModal
        api={onSubmit}
        questionId={questionId}
        renderButton={renderButton}
        refresh={fetchMyList}
        title="澄清咨询"
      />
    </DetailCard>
  )
}
