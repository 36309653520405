import { bizEnums, bizService, defs } from '@/services'

export const getFixedMoneyAmount = (v?: defs.Money) => {
  // 先按照4位小数取一下，确保小数位的四舍五入准确
  const amount = +(v?.amount || 0).toFixed(4)
  return +amount.toFixed(v?.currency ? bizService.getCurrencyFractionDigits(v.currency) : 0)
}

export const computeBidMoney = (data: {
  bidUnitUnTaxMoney?: defs.MoneyDto
  bidUnitMoney?: defs.MoneyDto
  quantity?: number
  taxRate?: number
  bidUnTaxMoney?: defs.MoneyDto
  bidMoney?: defs.MoneyDto
  moneyCalBenchmark?: defs.MoneyCalBenchmark
  orderMethod?: defs.PlaceOrderMethod
  currency?: defs.Currency
}) => {
  const {
    bidUnitUnTaxMoney,
    bidUnitMoney,
    quantity,
    taxRate,
    bidUnTaxMoney,
    bidMoney,
    moneyCalBenchmark,
    orderMethod,
    currency: optCurrency,
  } = data

  // 按数量下单
  if (orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY) {
    // 未税基准
    if (moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED) {
      const currency = bidUnitUnTaxMoney?.currency || optCurrency
      // 更新一下定标单价
      return {
        bidUnitUnTaxMoney,
        // 未税 * 税率
        bidUnitMoney: createMoney((bidUnitUnTaxMoney?.amount || 0) * (1 + (taxRate || 0)), currency),
        ...(quantity !== undefined
          ? {
              // 未税 * 个数
              bidUnTaxMoney: createMoney((bidUnitUnTaxMoney?.amount || 0) * (quantity || 0), currency),
              // 未税 * 税率 * 个数
              bidMoney: createMoney(
                (bidUnitUnTaxMoney?.amount || 0) * (1 + (taxRate || 0)) * (quantity || 0),
                currency
              ),
            }
          : { bidUnTaxMoney, bidMoney }),
      }
    }
    // 含税基准
    if (moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.TAXED) {
      const currency = bidUnitMoney?.currency || optCurrency
      return {
        bidUnitMoney,
        // 含税 / 税率
        bidUnitUnTaxMoney: createMoney((bidUnitMoney?.amount || 0) / (1 + (taxRate || 0)), currency),
        ...(quantity !== undefined
          ? {
              // (含税 / 税率) * 个数
              bidUnTaxMoney: createMoney(
                ((bidUnitMoney?.amount || 0) / (1 + (taxRate || 0))) * (quantity || 0),
                currency
              ),
              // 含税 * 个数
              bidMoney: createMoney((bidUnitMoney?.amount || 0) * (quantity || 0), currency),
            }
          : { bidUnTaxMoney, bidMoney }),
      }
    }
  }

  // 按金额下单
  if (orderMethod === bizEnums.PlaceOrderMethodEnum.AMOUNT) {
    // 未税基准
    if (moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED) {
      const currency = bidUnitUnTaxMoney?.currency || bidUnTaxMoney?.currency || optCurrency
      return {
        bidUnitUnTaxMoney,
        // 有些RFx上（线下询比价），报价金额会映射到单价金额上，所以按金额还是这里转算一下单价
        bidUnitMoney: createMoney((bidUnitUnTaxMoney?.amount || 0) * (1 + (taxRate || 0)), currency),
        // 使用报价币种，修订一下报价金额
        bidUnTaxMoney: createMoney(bidUnTaxMoney?.amount || 0, currency),
        bidMoney: createMoney((bidUnTaxMoney?.amount || 0) * (1 + (taxRate || 0)), currency),
      }
    }
    // 含税基准
    if (moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.TAXED) {
      const currency = bidUnitMoney?.currency || bidMoney?.currency || optCurrency
      return {
        bidUnitMoney,
        // 有些RFx上（线下询比价），报价金额会映射到单价金额上，所以按金额还是这里转算一下单价
        bidUnitUnTaxMoney: createMoney((bidUnitMoney?.amount || 0) / (1 + (taxRate || 0)), currency),
        // 含税 / 税率
        bidUnTaxMoney: createMoney((bidMoney?.amount || 0) / (1 + (taxRate || 0)), currency),
        // 使用报价币种，修订一下报价金额
        bidMoney: createMoney(bidMoney?.amount || 0, currency),
      }
    }
  }

  return data
}

export const correctBidMoneyAmountForAmountOrder = (data: {
  bidUnitUnTaxMoney?: defs.MoneyDto
  bidUnitMoney?: defs.MoneyDto
  bidUnTaxMoney?: defs.MoneyDto
  bidMoney?: defs.MoneyDto
  moneyCalBenchmark?: defs.MoneyCalBenchmark
  orderMethod?: defs.PlaceOrderMethod
}) => {
  const { bidUnitUnTaxMoney, bidUnitMoney, bidUnTaxMoney, bidMoney, moneyCalBenchmark, orderMethod } = data

  if (orderMethod === bizEnums.PlaceOrderMethodEnum.QUANTITY) return

  // 与报价金额比较，大与报价金额，取报价金额
  if (moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.UNTAXED) {
    if (bidUnTaxMoney && (bidUnTaxMoney.amount || 0) > (bidUnitUnTaxMoney?.amount || 0)) {
      bidUnTaxMoney.amount = bidUnitUnTaxMoney?.amount
    }
  } else if (moneyCalBenchmark === bizEnums.MoneyCalBenchmarkEnum.TAXED) {
    if (bidMoney && (bidMoney.amount || 0) > (bidUnitMoney?.amount || 0)) {
      bidMoney.amount = bidUnitMoney?.amount
    }
  }
}

export const changeMoneyCurrency = (money?: defs.MoneyDto, currency?: defs.Currency) => {
  return money && currency ? { currency, amount: getFixedMoneyAmount({ currency, amount: money.amount }) } : money
}

export const createMoney = (amount?: number, currency?: defs.Currency) => {
  if (amount === undefined || currency === undefined) return
  return { currency, amount: getFixedMoneyAmount({ currency, amount }) }
}

export const isEqualMoney = (a?: defs.MoneyDto, b?: defs.MoneyDto) => {
  return a?.currency === b?.currency && a?.amount === b?.amount
}

export const changeBidMoneyCurrency = (data: {
  bidUnitUnTaxMoney?: defs.MoneyDto
  bidUnitMoney?: defs.MoneyDto
  bidUnTaxMoney?: defs.MoneyDto
  bidMoney?: defs.MoneyDto
  prepaymentMoney?: defs.MoneyDto
  currency?: defs.Currency
}) => {
  const { bidUnitUnTaxMoney, bidUnitMoney, bidUnTaxMoney, bidMoney, prepaymentMoney, currency } = data

  return {
    bidUnitUnTaxMoney: changeMoneyCurrency(bidUnitUnTaxMoney, currency),
    bidUnitMoney: changeMoneyCurrency(bidUnitMoney, currency),
    bidUnTaxMoney: changeMoneyCurrency(bidUnTaxMoney, currency),
    bidMoney: changeMoneyCurrency(bidMoney),
    prepaymentMoney: changeMoneyCurrency(prepaymentMoney, currency),
  }
}
