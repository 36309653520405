import I18N from '@feature/i18n'

// tagMap 是用来存储一些常用的状态枚举样式。
export const TagEnumMap = {
  // 供应商账号状态
  supplierAccountStatus: {
    '1': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.qiYong,
    },
    '0': {
      textColor: '#394766',
      bgColor: '#F0F1F2',
      name: I18N.auto.jinYong,
    },
  },
  // 准入信息 阶段
  CooperateStatusEnum: {
    // 注册
    '01': {
      textColor: '#E08216',
      bgColor: '#FFF6E5',
      name: I18N.auto.zhuCe,
    },
    // 潜在/小金额
    '02': {
      textColor: '#1C80EB',
      bgColor: '#E0F3FF',
      name: I18N.auto.qianZaiXiaoJinE,
    },
    // 潜在/常规
    '03': {
      textColor: '#1C80EB',
      bgColor: '#E0F3FF',
      name: I18N.auto.qianZaiChangGui,
    },
    // 合作/常规
    '04': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.heZuoChangGui,
    },
    // 合作/小金额
    '05': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.heZuoXiaoJinE,
    },
    // 淘汰
    '06': {
      textColor: '#394766',
      bgColor: '#F0F1F2',
      name: I18N.auto.taoTai,
    },
    // 黑名单
    '07': {
      textColor: '#E55140',
      bgColor: '#FFEBE6',
      name: I18N.auto.heiMingDan,
    },
  },
  // 准入状态
  SpOaFlowStatusEnum: {
    // 草稿
    '01': {
      textColor: '#E08216',
      bgColor: '#FFF6E5',
      name: '待审核',
    },
    // 审批中
    '02': {
      textColor: '#1C80EB',
      bgColor: '#E0F3FF',
      name: I18N.auto.shenPiZhong,
    },
    // 审批通过
    '03': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.shenPiChengGong,
    },
    // 审批拒绝
    '04': {
      textColor: '#E55140',
      bgColor: '#FFEBE6',
      name: I18N.auto.shenPiJuJue,
    },
  },
  // 计提状态
  GrAccrualStatusMap: {
    UNCOMMITTED: {
      textColor: '#E08216',
      bgColor: '#FFF6E5',
      name: I18N.auto.weiQueRen,
    },
    COMMITTED: {
      textColor: '#004FD6',
      bgColor: '#E0F1FF',
      name: I18N.auto.yiTiJiao,
    },
    RETURNED: {
      textColor: '#E55140',
      bgColor: '#FFEBE6',
      name: I18N.auto.yiTuiHui,
    },
    UNCHECKED: {
      textColor: '#E08216',
      bgColor: '#FFF6E5',
      name: I18N.auto.weiShenHe,
    },
    CHECKED: {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.yiShenHe,
    },
    ACCOUNTED: {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.yiRuZhang,
    },
    CANCELED: {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.yiChongXiao,
    },
    REJECT: {
      textColor: '#E55140',
      bgColor: '#FFEBE6',
      name: I18N.auto.yiJuJue,
    },
  },
  // 下单规则状态
  MakeOrderRule: {
    '1': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.qiYong,
    },
    '0': {
      textColor: '#394766',
      bgColor: '#F0F1F2',
      name: I18N.auto.jinYong,
    },
  },
  // 收货单详情-资产检查结果
  AcceptanceCheckResultEnum: {
    '1': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.chengGong,
    },
    '0': {
      textColor: '#E55140',
      bgColor: '#FFEBE6',
      name: I18N.auto.shiBai,
    },
  },
  // 发票支付状态
  PayStatusStatusEnum: {
    // 未支付
    '01': {
      textColor: '#394766',
      bgColor: '#F0F1F2',
      name: I18N.auto.weiZhiFu,
    },
    // 已支付
    '02': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: I18N.auto.yiZhiFu,
    },
    // 部分支付
    '03': {
      textColor: '#394766',
      bgColor: '#F0F1F2',
      name: I18N.auto.buFenZhiFu,
    },
  },
  // 订单状态
  FmOrderLineStatusEnum: {
    // 待发货
    '1': {
      textColor: '#E08216',
      bgColor: '#FFF6E5',
      name: '待发货',
    },
    // 部分发货
    '2': {
      textColor: '#1C80EB',
      bgColor: '#E0F3FF',
      name: '部分发货',
    },
    // 已发货
    '3': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: '已发货',
    },
  },
  // 验收状态
  AcceptanceStatusEnum: {
    // 待验收
    '1': {
      textColor: '#E08216',
      bgColor: '#FFF6E5',
      name: '待验收',
    },
    // 部分验收
    '2': {
      textColor: '#1C80EB',
      bgColor: '#E0F3FF',
      name: '部分验收',
    },
    // 全部验收
    '3': {
      textColor: '#21A364',
      bgColor: '#E0FFEC',
      name: '全部验收',
    },
    // 已关闭
    '4': {
      textColor: '#E55140',
      bgColor: '#FFEBE6',
      name: '已关闭',
    },
  },
}
