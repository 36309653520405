import { useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { QRCodeSVG } from 'qrcode.react'
import dayjs from 'dayjs'
import { useRCMount } from '@oahz/neact-utils'
import { Button } from '@bedrock/components'
import { Printer } from '@bedrock/icons-react'
import { defs } from '@/services'
import { useSelector } from '@/store'
import './style.less'

const PrintOrder = (props: { data: defs.FmDeliveryFulfillmentDetailDto }) => {
  const { data } = props
  const { supplierInfo } = useSelector(state => state.account)
  const componentRef = useRef(null)
  const [hasNext, setHasNext] = useState(false)
  const [loading, setLoading] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      .page-header{
        position: ${hasNext ? 'fixed' : ''};
      }
      .order-items {
        margin-top: ${hasNext ? '168px' : '112px'};
      }
      .fill-block-qr{
        height: ${hasNext ? '70px' : '20px'};
      }
    `,
  })

  // 获取元素高度
  function getHiddenElementHeight(element) {
    if (!element) return
    setLoading(true)
    // 克隆元素
    const clone = element?.current?.cloneNode(true)

    // 设置克隆元素的样式
    clone.style.display = 'block'
    clone.style.visibility = 'hidden'
    clone.style.position = 'absolute'

    // 添加-计算-删除
    document.body.appendChild(clone)
    const height = clone.offsetHeight
    document.body.removeChild(clone)
    //判定是否存在第二页
    setHasNext(height > 910)
    setLoading(false)
    // return height > 100
  }

  useRCMount(() => {
    getHiddenElementHeight(componentRef)
  })

  const renderQRCode = (code: string, text: string) => {
    return (
      <div className="qr-item">
        <QRCodeSVG value={code} size={66} className="qr-code" />
        <div>{text}</div>
        <div>{code}</div>
      </div>
    )
  }

  return (
    <div>
      <Button type={'checked-neutral'} icon={<Printer />} loading={loading} onClick={handlePrint}>
        打印
      </Button>
      <div ref={componentRef} className="shipping-order">
        <div className="page-header">
          <div className="page-header-content">
            <div className="page-header-title">发货单</div>
            <div className="page-header-data">打印日期: {dayjs().format('YYYY-MM-DD')}</div>
          </div>
          <div className="page-header-info">
            <div>
              <span>发货单号：</span>
              {data?.id}
            </div>
            <div>
              <span>采购方：</span>
              {data?.settlementCompanyName}
            </div>
            <div>
              <span>供应商：</span>
              {supplierInfo.name}
            </div>
          </div>
        </div>
        <div className="order-info">
          <div className="fill-block"></div>
          <div className="order-info-content">
            <div className="left-info">
              <div className="order-info-title">收货信息</div>
              <div className="info-item">
                <div className="label">收货人： </div>
                <div>{data?.receivingInfo?.receiver}</div>
              </div>
              <div className="info-item">
                <div className="label">电话：</div>
                <div>{data?.receivingInfo?.contactWay}</div>
              </div>
              <div className="info-item">
                <div className="label">收货地址:</div>
                <div className="address">{data?.receivingInfo?.address}</div>
              </div>
              <div>
                <div className="label">备注:</div>
                <div></div>
              </div>
            </div>
            <div className="right-info">
              <div className="order-info-title">发货信息</div>
              <div className="info-item">
                <div className="label">送货人:</div>
                <div></div>
              </div>
              <div className="info-item">
                <div className="label">电话：</div>
                <div></div>
              </div>
              <div className="info-item">
                <div className="label">发货地址:</div>
                <div className="address"></div>
              </div>
              <div className="info-item">
                <div className="label">发货日期:</div>
                <div>{dayjs(data?.deliveryInfo?.deliveryTime).format('YYYY-MM-DD')}</div>
              </div>
            </div>
          </div>
          <div className="table-title">发货明细</div>
        </div>
        <div className="order-items-container">
          <table className="order-items">
            <thead>
              <div className="fill-block"></div>
              <tr>
                <th style={{ width: '6%' }}>序号</th>
                <th style={{ width: '10%' }}>订单行号</th>
                <th style={{ width: '20%' }}>商品信息</th>
                <th>品牌</th>
                <th style={{ width: '20%' }}>配置</th>
                <th style={{ width: '8%' }}>数量</th>
                <th style={{ width: '8%' }}>单位</th>
                <th style={{ width: '12%' }}>备注</th>
              </tr>
            </thead>
            <tbody>
              {data?.lines?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.orderLineNo?.split('-').pop()}</td>
                  <td>{item.goodsName}</td>
                  <td>{item.goodsBrand}</td>
                  <td>{item.goodsConfiguration}</td>
                  <td>{item.quantity}</td>
                  <td></td>
                  <td>{item.remark}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="signatures">
          <p>送货人: </p>
          <p>收货人: </p>
          <p>检验员: </p>
        </div>
        <div className="qr">
          <div className="fill-block-qr"></div>
          <div className="qr-list">
            {renderQRCode(data?.id!, '发货单号')}
            {renderQRCode(data?.lines?.[0]?.oaNo!, 'OA单号')}
            {renderQRCode(data?.orderNo!, '订单号')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrintOrder
