import { useEffect, useState } from 'react'
import I18N from '@feature/i18n'
import EchoPages, { PagesType, LangType, EchoPagesProps } from '@echo/pages'
import { BEMGenerator, useBoolean } from '@oahz/neact-utils'
import { Close, MessagesFilled } from '@bedrock/icons-react'
import './style.less'

const bem = BEMGenerator('ftev')

// TODO: 放在这里最好要做的是，在以后比如协同平台也要接echo的话，将这个健全作为扩展项，然后提供的是echo的这个能力和ui
export const EchoView = (
  props: Omit<EchoPagesProps, 'sign'> & { className?: string; login: (opts?: any) => Promise<string | undefined> }
) => {
  const { className, login, ...reset } = props
  const [visible, [show, hide]] = useBoolean(false)
  const [sign, setSign] = useState('')

  const doLogin = async () => {
    const res = await login()
    res && setSign(res)
    return { sign: res || '' }
  }

  const onShow = async () => {
    await doLogin()
    show()
  }

  useEffect(() => {
    // 从服务端获取sign
    doLogin()
  }, [])
  return !sign ? null : (
    <div className={bem([className])}>
      {!visible && (
        <div className={bem('btn')}>
          <div className={bem('btn-hide')}>
            <MessagesFilled size={24} />
          </div>
          <div className={bem('btn-show')} onClick={onShow}>
            <MessagesFilled size={24} />
            <div className={bem('btn-show-text')}>{I18N.auto.bangZhuFanKui}</div>
          </div>
        </div>
      )}
      {visible && (
        <div className={bem('echo')}>
          <div className={bem('echo-header')}>
            <div>{I18N.auto.bangZhunZhongXin}</div>
            <Close size={24} onClick={hide} />
          </div>
          <EchoPages
            {...reset}
            sign={sign}
            event={{
              onReLogin: doLogin,
            }}
          />
        </div>
      )}
    </div>
  )
}
