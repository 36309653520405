import { ReactNode } from 'react'
import { BEMGenerator } from '@oahz/neact-utils'
import I18N from '@feature/i18n'
import { Link } from '@oahz/neact'
import { Button } from '@bedrock/components'
import { ELightSelect, ELightDatePicker, type QueryParams } from '@bedrock-e/common'
import { AppTable, createTableColumns, AppDefaultTableHeader, ShowCutOffTime, DataMoney } from '@feature/views'
import { formatDateTime, ZoneValue } from '@feature/shared'
import { QuotationStatus, DataLink } from '@/components/data-view'
import { PageContainer, PageList } from '@/components/page-view'
import { apiService, bizEnums, type defs } from '@/services'
import './style.less'

const bem = BEMGenerator('pset')

export const TenderTable = (props: { entrance?: string }) => {
  const { entrance } = props

  const linkToPage = (text: ReactNode, record: defs.QuotationTenderWorkbenchListDto, isOption?: boolean) => {
    const { id, respondTender, status } = record
    let link = `/tender/detail?id=${id}`
    if (
      [
        bizEnums.QuotationStatusEnum.WAIT_RESPOND_TENDER,
        bizEnums.QuotationStatusEnum.ABANDONED_BID,
        bizEnums.QuotationStatusEnum.NOT_BID,
      ].includes(status) &&
      !respondTender
    ) {
      link = `/tender/offer?id=${id}`
    }
    return isOption ? (
      <Link to={link} target="_blank">
        <Button type="text">{text}</Button>
      </Link>
    ) : (
      <DataLink href={link}>{text}</DataLink>
    )
  }

  const renderOption = (record: defs.QuotationTenderWorkbenchListDto) => {
    const { status } = record

    let text = '查看'
    if (status === bizEnums.QuotationStatusEnum.WAIT_RESPOND_TENDER) text = '应标'
    else if (status === bizEnums.QuotationStatusEnum.WAIT_TENDER) text = '投标'

    return linkToPage(text, record, true)
  }

  const colums = (props: { doRequest: () => void }) => {
    return createTableColumns<defs.QuotationTenderWorkbenchListDto>([
      {
        dataIndex: 'rfxId',
        title: '招标事件号',
        width: 200,
        align: 'left',
        fixed: 'left',
        paragraph: true,
        render: (v, record) => linkToPage(v, record),
      },
      {
        dataIndex: 'status',
        title: '状态',
        width: 100,
        align: 'left',
        render: v => <QuotationStatus value={v} />,
      },
      {
        dataIndex: 'title',
        title: '招标书标题',
        width: 240,
        align: 'left',
      },
      {
        dataIndex: 'timeZone',
        title: '时区',
        width: 120,
        align: 'left',
        render: v => <ZoneValue value={v} />,
      },
      {
        dataIndex: 'beginAt',
        title: '投标开始时间',
        width: 180,
        align: 'left',
        render: v => formatDateTime(v),
      },
      {
        dataIndex: 'endAt',
        title: '投标结束时间',
        width: 180,
        align: 'left',
        render: (_, record) => (
          <ShowCutOffTime
            targetTime={record.isClarification ? record.clarificationDeadlineAt : record.endAt}
            format="YYYY-MM-DD HH:mm:ss"
          />
        ),
      },
      {
        dataIndex: 'tenderCompany',
        title: '招标公司',
        width: 240,
        align: 'left',
        ellipsis: true,
      },
      {
        dataIndex: 'id',
        title: '操作',
        width: 80,
        align: 'left',
        render: (v, record) => renderOption(record),
      },
    ])
  }

  let queryItems = [
    {
      name: 'statusSet',
      label: I18N.auto.zhuangTai,
      children: <ELightSelect multiple={false} options={bizEnums.QuotationDisplayTenderStatusEnum.values()} />,
    },
    {
      name: 'rangeDate',
      label: '投标截止时间',
      children: <ELightDatePicker range />,
    },
  ]

  const _queryItems = entrance === 'workbench' ? queryItems.slice(1, 2) : queryItems

  //列表头部
  const renderHeader = ({ setting, doRequest }: { setting: ReactNode; doRequest: (params?: any) => void }) => (
    <AppDefaultTableHeader
      onQuery={doRequest}
      queryItems={_queryItems}
      queryInput={{
        placeholder: '请输入BID单号/招标书标题',
      }}
    />
  )

  const request = (params: Pick<QueryParams, 'orderItemList'>) => {
    const api =
      entrance === 'workbench' ? apiService.quotationWorkbenchQueryQuotationTenderList : apiService.quotationTenderList
    // @ts-ignore
    const [beginAt, endAt] = params.rangeDate || []
    const genParmas: defs.QuotationTenderQuery = {
      ...params,
      beginAt,
      endAt,
      // @ts-ignore
      rangeDate: undefined,
    }
    return api(genParmas)
  }

  return (
    <AppTable
      rowKey={'id'}
      settable={{ id: 'tender-list' }}
      request={request}
      className={bem(entrance === 'workbench' ? 'table-workbench' : 'table-tender')}
      renderColumns={colums}
      renderHeader={renderHeader}
    />
  )
}

export default () => {
  return (
    <PageContainer className={bem()} htmlTitle={'投标管理'}>
      <PageList className={bem('list')}>
        <TenderTable />
      </PageList>
    </PageContainer>
  )
}
