import { useRoute } from '@oahz/neact'
import { BEMGenerator, useRCVars } from '@oahz/neact-utils'
import { Tabs, Typography } from '@bedrock/components'
import I18N from '@feature/i18n'
import { useSelector } from '@/store'
import { PageContainer } from '@/components/page-view'
import Ship from './ship'
import Quote from './quote'
import { TenderTable } from '../tender'
import './style.less'

const bem = BEMGenerator('pwb')

export default () => {
  const { query } = useRoute()
  const vars = useRCVars({ active: query.active })
  const workbenchStatistics = useSelector(state => state.workbenchStatistics)

  return (
    <PageContainer className={bem()} htmlTitle={I18N.auto.gongZuoTai}>
      <Tabs destroyInactiveTabPane className={bem('tabs')} type="card" defaultActiveKey={vars.active || '1'}>
        <Tabs.TabPane
          tab={<TabTitle icon={'1'} name={'待报价'} total={workbenchStatistics?.quotation?.pendingQuotationNum} />}
          key="1">
          <Quote />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <TabTitle icon={'tender'} name={'待投标'} total={workbenchStatistics?.pendingBidding?.pendingBiddingNum} />
          }
          key="2">
          <TenderTable entrance={'workbench'} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<TabTitle icon={'2'} name={'待发货'} total={workbenchStatistics?.shipping?.pendingShippingNum} />}
          key="3">
          <Ship />
        </Tabs.TabPane>
      </Tabs>
    </PageContainer>
  )
}

const TabTitle = (props: { icon: string; name?: string; total?: number; urgentNum?: number; otherTagNum?: number }) => {
  const { icon, name, total } = props
  return (
    <div className={bem('tt')}>
      <div className={bem('tt-row')}>
        <img className={bem('tt-icon')} src={require(`@/assets/index-tab-${icon}.png`)} alt="icon" />
        <div className={bem('tt-box')}>
          <Typography.Paragraph className={bem('tt-name')} ellipsis>
            {name}
          </Typography.Paragraph>
          <div className={bem('tt-total')}>{total}</div>
        </div>
      </div>
    </div>
  )
}
