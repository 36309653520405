import { createRouter, neact } from '@oahz/neact'
import { appScout } from '@oahz/neact-utils'
import { ConfigProvider } from '@bedrock/components'
import { getLanguage } from '@feature/i18n'
import { StorePrivder, store } from '@/store'
import '@/styles/index.less'

// 应用前置，如下用户信息获取，那接口nxios那边会拦截如4001的情况
const bootstrap = async () => {
  await appScout.init({
    // @ts-ignore 在 .neactrc.ts 中定义
    appId: SCOUT_APP_ID,
    instrumentEnable: {
      fetch: true, // 启动fetch劫持
      xhr: true, // 启动xhr劫持
    },
  })
}

const App = () => {
  const { rootElement } = createRouter({
    routes: [
      {
        path: '/',
        component: require('@/layouts/index'),
        children: [
          { path: '/', redirect: '/workbench' },
          { path: '/workbench', component: require('@/pages/workbench') },
          { path: '/quotation', component: require('@/pages/quotation') },
          { path: '/quotation/show', component: require('@/pages/quotation/detail') },
          {
            path: '/order',
            component: require('@/layouts/order'),
            children: [
              { path: '/', redirect: '/order/list' },
              { path: '/list', component: require('@/pages/order/order-list') },
              { path: '/show', component: require('@/pages/order/order-show') },
              { path: '/ship-create', component: require('@/pages/order/ship-create') },
              { path: '/ship-show', component: require('@/pages/order/ship-show') },
              { path: '/delivery-list', component: require('@/pages/order/delivery-list') },
              { path: '/delivery-show', component: require('@/pages/order/ship-show') },
            ],
          },
          { path: '/tender', component: require('@/pages/tender') },
          { path: '/tender/detail', component: require('@/pages/quotation/detail') },
          { path: '/tender/offer', component: require('@/pages/tender/offer') },
          { path: '/invoice', component: require('@/pages/invoice') },
          {
            path: '/enterprise',
            children: [
              { path: '/', component: require('@/pages/enterprise/index') },
              { path: '/resubmit', component: require('@/pages/enterprise/onbound-resubmit') },
              { path: '/info-update', component: require('@/pages/enterprise/info-update') },
              { path: '/info-changes', component: require('@/pages/enterprise/info-changes') },
            ],
          },
          { path: '/setpwd', component: require('@/pages/setpwd') },
        ],
      },
      { path: '/login', component: require('@/pages/signin/login') },
      { path: '/resetpwd', component: require('@/pages/signin/resetpwd') },
      { path: '/register', component: require('@/pages/signin/register') },
      { path: '/register-admin', component: require('@/pages/signin/register-admin') },
    ],
    // onRouteChange: () => {},
  })

  return (
    <StorePrivder store={store}>
      <ConfigProvider locale={getLanguage()}>{rootElement}</ConfigProvider>
    </StorePrivder>
  )
}

neact({ bootstrap, App })
