import I18N from '@feature/i18n'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import { useDebouncedCallback } from 'use-debounce'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)

export * from './popo'

export const formatAmountNumber = (v = 0) => {
  return v > 99 ? '99+' : v
}

export const formatDateTime = (v?: string, format = 'YYYY-MM-DD HH:mm:ss') => {
  return v ? dayjs(v).format(format) : '-'
}

export const dataDiff = (targetTime?: string, unit = 'hour', format = 'YYYY-MM-DD HH:mm:ss') => {
  return targetTime ? dayjs(dayjs(targetTime).format(format)).diff(dayjs().format(format), unit as 'hour') : 0
}

export const formatStartEndDate = (startAt?: string, endAt?: string, format = 'YYYY-MM-DD') => {
  if (endAt && dayjs(endAt).year() === 2099) return I18N.auto.yongJiuYouXiao
  return startAt && endAt ? `${formatDateTime(startAt, format)} ~ ${formatDateTime(endAt, format)}` : '-'
}

export const formatUtcDateTime = (v?: string, format = 'YYYY-MM-DD HH:mm:ss') => {
  return v ? dayjs.utc(v).local().format(format) : '-'
}

export const formatUtcTimeZone = (v?: string, format = 'YYYY-MM-DD HH:mm:ss (UTCZ)') =>
  v ? dayjs(v).format(format) : '-'

// 获取当前时区
export const getTimeZone = () => {
  const timeOffset = new Date().getTimezoneOffset()
  const zone = 0 - timeOffset / 60
  return `UTC${zone.toLocaleString('en-US', { signDisplay: 'always' })}`
}

export const openNewTab = (opts: { url: string; download?: string }) => {
  const el = document.createElement('a')
  el.setAttribute('href', opts.url)
  opts.download && el.setAttribute('download', opts.download)
  el.setAttribute('rel', 'noreferrer')
  el.setAttribute('target', '_blank')
  el.style.display = 'none'
  document.body.appendChild(el)
  el.click()
  document.body.removeChild(el)
}

export const openFile = (url: string) => openNewTab({ url })

export const downloadFile = (url: string, name = '') => {
  // 移动端使用bridge下载文件
  if (isMobile()) {
    jsbridge.callHandler('downLoadFile', JSON.stringify({ url, path: name, taskId: '1', method: 'GET' }))
    return
  }
  openNewTab({ url, download: name })
}

// Safari iOS上，iframe嵌入页面中，直接a标签不能下载非同源的文件，会警告提示如下：
// The download attribute on anchor was ignored because its href URL has a different security origin
export const fetchDownloadFile = (url: string, name = '') => {
  fetch(url)
    .then(response => response.blob())
    .then(blob => {
      const blobUrl = URL.createObjectURL(blob)
      downloadFile(blobUrl, name)
      URL.revokeObjectURL(blobUrl)
    })
}

export const usePrestDebounceCallback: typeof useDebouncedCallback = fn =>
  useDebouncedCallback(fn, 500, { leading: true, trailing: false })

export const isMobile = () => {
  return /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export const jsbridge = {
  // setIframeHeight -> 给OA客户端通知iframe的实际高度
  callHandler: function (name: string, data: any) {
    // @ts-ignore
    window.ReactNativeWebView &&
      // @ts-ignore
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: '$$bridge',
          data: {
            method: name,
            params: data,
          },
        })
      )
  },
  callResult: function <T = any>(name: string, data: any = {}): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      // 定义消息处理函数
      const handleMessage = (evt: any) => {
        try {
          const message = JSON.parse(evt.data) // 确保消息内容是JSON格式
          if (message?.type === '$$bridge' && message?.data?.method === name) {
            resolve(message.data.response)
            window.removeEventListener('message', handleMessage)
          }
        } catch (error) {
          reject(error)
        }
      }
      window.addEventListener('message', handleMessage)
      jsbridge.callHandler(name, data)
    })
  },
  registerHandler: function (name: string, callback: (v: any) => void) {
    //注册回调
  },
}

export const getMoneyNumberSign = (num: number) => {
  if (num < 10_000) {
    return ''
  } else if (num < 100_000) {
    return I18N.auto.wan
  } else if (num < 1_000_000) {
    return I18N.auto.shiWan
  } else if (num < 10_000_000) {
    return I18N.auto.baiWan
  } else if (num < 100_000_000) {
    return I18N.auto.qianWan
  } else if (num < 1_000_000_000) {
    return I18N.auto.yi
  } else if (num < 10_000_000_000) {
    return I18N.auto.shiYi
  } else if (num < 100_000_000_000) {
    return I18N.auto.baiYi
  } else if (num < 1_000_000_000_000) {
    return I18N.auto.qianYi
  } else {
    return '>' + I18N.auto.qianYi
  }
}

const CHINA_PERSON_ID_RE = /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}[xX0-9]$/
export const checkChinaPersonId = (id: string) => {
  return CHINA_PERSON_ID_RE.test(id)
}

const CHINA_ENTERPRISE_CREDIT_CODE_RE = /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/
export const checkChinaEnterpriseCreditCode = (id: string) => {
  return CHINA_ENTERPRISE_CREDIT_CODE_RE.test(id)
}

export const ZoneValue = (props: { value?: number }) => {
  return props.value === 0 ? 'UTC' : !props.value ? '-' : 'UTC' + (props.value > 0 ? '+' : '') + props.value
}

export const formatMoenyAmount = (value?: { amount?: number; currency?: string }) => {
  if (value?.amount === undefined) return ''
  let amount: number | string = value?.amount || 0
  let currency = value?.currency

  // 判断是否需要保留小数位
  if (currency === 'JPY' || currency === 'KRW') {
    amount = Math.floor(amount) // 去掉小数位
  } else {
    amount = amount.toFixed(2) // 保留两位小数
  }

  // 处理千分位
  return `${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const getFileSizeUnit = (fileSizeInBytes: number): string => {
  const KB = 1024
  const MB = 1024 * KB
  const GB = 1024 * MB

  let size: number
  let unit: string

  if (fileSizeInBytes >= GB) {
    size = fileSizeInBytes / GB
    unit = 'GB'
  } else if (fileSizeInBytes >= MB) {
    size = fileSizeInBytes / MB
    unit = 'MB'
  } else {
    size = fileSizeInBytes / KB
    unit = 'KB'
  }

  // 保留两位小数
  const formattedSize = size.toFixed(2)

  return `${formattedSize}${unit}`
}
