import { BEMGenerator } from '@oahz/neact-utils'
import { Button } from '@bedrock/components'
import { Link } from '@oahz/neact'
import { Clock } from '@bedrock/icons-react'
import { Bill } from '@bedrock/icons-react'
import { ShowCutOffTime } from '@feature/views'
import dayjs from 'dayjs'
import type { defs } from '@/services'
import { WorkbenchListItem, ListFieldView } from '../'
import './style.less'

const bem = BEMGenerator('cwb-quote')

const QuoteListItemHeader = (props: { data: defs.QuotationWorkbenchListDto }) => {
  const { data } = props
  return (
    <div className={bem('qlih')}>
      <div className={bem('qlih-info')}>
        <div className={bem('qlih-info-rfxId')}>{data.rfxId || '-'}</div>
        <div className={bem('qlih-info-name')}>{data.settlementCompanyName || '-'}</div>
      </div>
      <ShowCutOffTime
        targetTime={data.isClarification ? data.clarificationDeadlineAt : data.deadlineAt}
        prefix={<Clock />}
        stuffix={<span>{'截止'}</span>}
      />
    </div>
  )
}

const QuoteListItemFooter = (props: {
  lines: defs.QuotationLineWorkbenchListDto[]
  id?: string
  deadlineAt?: string
}) => {
  const { lines, id, deadlineAt } = props
  return (
    <div className={bem('qlif')}>
      <div className={bem('qlif-count')}>
        共<span>{lines.length}</span>行商品信息
      </div>
      <Link to={`/quotation/show?id=${id}`} target="_blank">
        <Button disabled={dayjs().isAfter(deadlineAt)} type="primary" icon={<Bill />}>
          报价
        </Button>
      </Link>
    </div>
  )
}

const QhipListItemDataView = (props: { data: defs.QuotationLineWorkbenchListDto[] }) => {
  const { data } = props
  return (
    <div className={bem('qlidv')}>
      {data.slice(0, 3).map(item => {
        return (
          <div className={bem('qlidv-item')} key={item.id}>
            <div className={bem('qlidv-item-name')} title={item.goodsName}>
              {item.goodsName}
            </div>
            <div className={bem('qlidv-item-brand')}>
              <ListFieldView label="品牌" value={item.goodsBrand} />
            </div>
            <div className={bem('qlidv-item-config')}>
              <ListFieldView label="配置" value={item.goodsConfiguration} />
            </div>
            <div className={bem('qlidv-item-number')}>
              <ListFieldView label="询价数量" value={item.quantity} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const QuoteListItemView = (props: { data: defs.QuotationWorkbenchListDto }) => {
  const { data } = props
  if (!data.lines) return <></>
  return (
    <section className={bem()}>
      <WorkbenchListItem
        header={<QuoteListItemHeader data={data} />}
        footer={<QuoteListItemFooter lines={data.lines} id={data.id} deadlineAt={data.deadlineAt} />}>
        <QhipListItemDataView data={data?.lines || []} />
      </WorkbenchListItem>
    </section>
  )
}
